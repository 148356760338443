import { useMutation } from "@tanstack/react-query";
import useBankAccountApi from "modules/bank-accounts/api/useBankAccountApi";
import { useRefreshAllBankAccountQueries } from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import { notify } from "ui/feedback/Toast";

type Variables = {
  accountGuid: string;
  accountName: string;
};

const useEditAccountNameMutation = () => {
  const bankAccountApi = useBankAccountApi();

  const refreshAllBankAccountQueries = useRefreshAllBankAccountQueries();

  return useMutation({
    mutationFn: async ({ accountGuid, accountName }: Variables) =>
      await bankAccountApi.update(accountGuid, { name: accountName }),
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
    },
    onSuccess: async () => {
      await refreshAllBankAccountQueries();
      notify("success", "Account name updated successfully!");
    },
  });
};

export default useEditAccountNameMutation;
