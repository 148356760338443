import { FC, ReactNode } from "react";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import {
  ChartColor,
  getChartBackgroundColorClass,
  getChartTextColorClass,
} from "./chart-color-utils";

const ChartTooltipHeader: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <div className={cn("flex items-center gap-x-2 !text-xs font-medium", className)}>
      {children}
    </div>
  );
};

const ChartTooltipBody: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("mt-3 flex flex-col gap-y-2", className)}>{children}</div>;
};

type ChartTooltipItemProps = {
  className?: string;
  color: ChartColor;
  label: ReactNode;
  value: ReactNode;
};

const ChartTooltipItem: FC<ChartTooltipItemProps> = ({ color, label, value, className }) => {
  return (
    <div className={cn("flex items-center justify-between gap-x-2 text-xs", className)}>
      <div className="flex items-center gap-x-2 pr-4">
        <span className={cn("h-0.5 w-3", getChartBackgroundColorClass(color))} />
        <span className={cn(getChartTextColorClass(color))}>{label}</span>
      </div>
      <span className="font-medium text-grey-800">{value}</span>
    </div>
  );
};

const ChartTooltip: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("font-main", className)}>{children}</div>;
};

export default Object.assign(ChartTooltip, {
  Header: ChartTooltipHeader,
  Body: ChartTooltipBody,
  Item: ChartTooltipItem,
});
