import HighbeamBaseApi from "api/HighbeamBaseApi";
import UuidSelection from "reps/UuidSelection";

export default class ChatRefreshApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async execute(businessGuid: string): Promise<void> {
    const url = "/chat/refresh/execute";
    await this.api.post(url, {
      businessGuids: { type: "Explicit", guids: [businessGuid] } satisfies UuidSelection,
    });
  }
}
