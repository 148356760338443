// Custom select implementation based on @radix-ui/react-select primitives.
// Shadcn's Select component is used as an initial reference implementation.

import {
  CaretUp as CaretUpIcon,
  CaretDown as CaretDownIcon,
  Check as CheckIcon,
} from "@phosphor-icons/react";
import * as LabelPrimitive from "@radix-ui/react-label";
import * as SelectPrimitive from "@radix-ui/react-select";
import {
  FC,
  PropsWithChildren,
  ComponentPropsWithoutRef,
  ReactNode,
  createContext,
  useContext,
  useId,
  useCallback,
} from "react";
import useTopLayerPortalContainer from "ui/hooks/useTopLayerPortalContainer";
import { InputVariant } from "ui/inputs/InputWrapperV2";
import cn from "utils/tailwind/cn";

// Radix select's don't allow items with value set to an empty string or null.
// When we provide a select item intended to "clear" the select input,
// we set its value to the senitnel EMPTY_ITEM_VALUE;
export const EMPTY_ITEM_VALUE = "__empty__";

type ListboxContextValue = {
  variant: InputVariant;
  ghost: boolean;
  baseId: string;
  value: string | null;
  onValueChange: (value: string | null) => void;
  disabled?: boolean;
};

const ListboxContext = createContext<ListboxContextValue>({} as ListboxContextValue);

const useListboxContext = () => {
  const context = useContext(ListboxContext);
  if (!context) {
    throw new Error("useListboxContext must be used within a Listbox");
  }
  return context;
};

const makeTriggerId = (baseId: string) => `${baseId}-trigger`;

type ListboxFieldProps = PropsWithChildren<{
  className?: string;
}>;

const ListboxField: FC<ListboxFieldProps> = ({ children, className }) => (
  <div className={cn("relative", className)}>{children}</div>
);

type ListboxLabelProps = PropsWithChildren<{
  className?: string;
}>;

const ListboxLabel: FC<ListboxLabelProps> = ({ children, className }) => {
  const { baseId, value, variant } = useListboxContext();
  const triggerId = makeTriggerId(baseId);
  const shouldShrink = variant !== "minimal" && Boolean(value) && value !== EMPTY_ITEM_VALUE;

  return (
    <LabelPrimitive.Root
      htmlFor={triggerId}
      data-shrink={shouldShrink}
      className={cn(
        "peer/label pointer-events-none absolute left-0 top-0 flex h-full max-w-[calc(100%-2rem)] items-center justify-start truncate px-4 text-sm leading-none text-grey-400",
        {
          "text-xs font-bold text-grey-700 [&>span]:-top-2.5": shouldShrink,
          "sr-only": variant === "minimal",
        },
        className
      )}
    >
      <span className="relative">{children}</span>
    </LabelPrimitive.Root>
  );
};

type ListboxTriggerRenderProps = {
  value: string | null;
};

type ListboxTriggerProps = {
  className?: string;
  children?: ReactNode | ((props: ListboxTriggerRenderProps) => ReactNode);
};

const ListboxTrigger: FC<ListboxTriggerProps> = ({ children, className }) => {
  const { baseId, value, variant, ghost } = useListboxContext();
  const triggerId = makeTriggerId(baseId);

  return (
    <SelectPrimitive.Trigger
      id={triggerId}
      className={cn(
        "data-[state=open]:input-focus-outline enabled:focus:input-focus-outline group/trigger inline-flex w-full items-center justify-between rounded-md px-4 text-sm enabled:focus:outline-none disabled:cursor-not-allowed disabled:bg-grey-50 data-[placeholder]:text-grey-400 [&>span]:line-clamp-1 peer-data-[shrink=true]/label:[&>span]:top-2.5",
        !ghost && "border border-solid border-grey-200",
        {
          "h-[3.25rem]": variant === "default",
          "h-10": variant === "minimal",
        },
        className
      )}
    >
      <span
        className={cn("relative", {
          "text-grey-600": variant !== "minimal",
        })}
      >
        {children ? (
          typeof children === "function" ? (
            children({ value })
          ) : (
            children
          )
        ) : (
          <SelectPrimitive.Value />
        )}
      </span>
      <SelectPrimitive.Icon asChild>
        <>
          <CaretDownIcon className="h-4 w-4 text-grey-500 group-disabled/trigger:hidden group-data-[state=open]/trigger:hidden" />
          <CaretUpIcon className="h-4 w-4 text-grey-500 group-disabled/trigger:hidden group-data-[state=closed]/trigger:hidden" />
        </>
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  );
};

type ListboxMenuProps = ComponentPropsWithoutRef<typeof SelectPrimitive.Content>;

const ListboxMenu: FC<ListboxMenuProps> = ({
  children,
  className,
  position = "popper",
  ...props
}) => {
  const portalContainer = useTopLayerPortalContainer();

  return (
    <SelectPrimitive.Portal container={portalContainer}>
      <SelectPrimitive.Content
        className={cn(
          "relative z-50 min-w-[8rem] overflow-hidden rounded-md border border-grey-200 bg-white shadow-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          position === "popper" &&
            "max-h-96 data-[side=bottom]:top-1 data-[side=left]:-left-1 data-[side=right]:left-1 data-[side=top]:-top-1",
          className
        )}
        position={position}
        {...props}
      >
        <SelectPrimitive.ScrollUpButton
          className={cn(
            "flex cursor-default items-center justify-center border-b border-grey-100 py-1",
            className
          )}
        >
          <CaretUpIcon className="h-4 w-4" />
        </SelectPrimitive.ScrollUpButton>
        <SelectPrimitive.Viewport
          className={cn(
            position === "popper" &&
              "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]"
          )}
        >
          {children}
        </SelectPrimitive.Viewport>
        <SelectPrimitive.ScrollDownButton
          className={cn(
            "flex cursor-default items-center justify-center border-t border-grey-100 py-1",
            className
          )}
        >
          <CaretDownIcon className="h-4 w-4" />
        </SelectPrimitive.ScrollDownButton>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
};

type ListboxItemProps = ComponentPropsWithoutRef<typeof SelectPrimitive.Item>;

const ListboxItem: FC<ListboxItemProps> = ({ children, className, ...props }) => (
  <SelectPrimitive.Item
    className={cn(
      "flex w-full cursor-pointer select-none items-center justify-between border-0 border-t border-grey-200 px-4 py-3 text-sm outline-none focus:bg-grey-50 data-[disabled]:pointer-events-none data-[disabled]:bg-grey-100 data-[disabled]:text-grey-400",
      className
    )}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>

    <span className="ml-2 flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <CheckIcon className="h-4 w-4 text-purple-500" />
      </SelectPrimitive.ItemIndicator>
    </span>
  </SelectPrimitive.Item>
);

const ListboxEmptyItem: FC = () => <ListboxItem value={null!} />;

type Props = Omit<
  ComponentPropsWithoutRef<typeof SelectPrimitive.Root>,
  "value" | "onValueChange"
> & {
  value: string | null;
  onValueChange: (value: string | null) => void;
  variant?: InputVariant;
  ghost?: boolean;
};

const Listbox: FC<Props> = ({
  value,
  onValueChange,
  disabled,
  children,
  variant = "default",
  ghost = false,
  ...props
}) => {
  const baseId = useId();

  const shimmedValue = value === null ? EMPTY_ITEM_VALUE : value;

  const shimmedOnValueChange = useCallback(
    (value: string | null) => {
      if (value === null) {
        onValueChange(EMPTY_ITEM_VALUE);
      } else {
        onValueChange(value);
      }
    },
    [onValueChange]
  );

  return (
    <SelectPrimitive.Root
      value={shimmedValue}
      onValueChange={shimmedOnValueChange}
      disabled={disabled}
      {...props}
    >
      <ListboxContext.Provider value={{ baseId, value, onValueChange, disabled, variant, ghost }}>
        {children}
      </ListboxContext.Provider>
    </SelectPrimitive.Root>
  );
};

export default Object.assign(Listbox, {
  Field: ListboxField,
  Label: ListboxLabel,
  Trigger: ListboxTrigger,
  Menu: ListboxMenu,
  Item: ListboxItem,
  EmptyItem: ListboxEmptyItem,
});
