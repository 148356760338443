import { ArrowRight } from "@phosphor-icons/react";
import { ForwardedRef, forwardRef, useRef } from "react";
import { Controller } from "react-hook-form";
import Button from "ui/inputs/Button";
import TextArea from "ui/inputs/TextArea";
import { composeRefs, useComposedRefs } from "utils/react-helpers/compose-refs";
import cn from "utils/tailwind/cn";

import useChatMessageForm, {
  type ChatMessageFormOutputs,
  type ChatMessageFormInputs,
} from "./useChatMessageForm";

type Props = {
  onSubmit: (data: ChatMessageFormOutputs) => Promise<void>;
  form: ReturnType<typeof useChatMessageForm>;
  textAreaRef?: ForwardedRef<HTMLTextAreaElement>;
  className?: string;
};

const ChatMessageForm = forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, form, textAreaRef, className }, forwardedRef) => {
    const internalFormRef = useRef<HTMLFormElement>(null);
    const formRef = useComposedRefs(internalFormRef, forwardedRef);

    return (
      <form
        ref={formRef}
        className={cn("relative w-full", className)}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Controller
          name="content"
          control={form.control}
          render={({ field: { ref, ...field } }) => {
            return (
              <TextArea
                {...field}
                className="bg-white"
                rows={3}
                placeholder="Ask any question about your business’s finances."
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    internalFormRef.current!.requestSubmit();
                  }
                }}
              >
                <TextArea.Input ref={composeRefs(ref, textAreaRef)} className="pr-11" />
              </TextArea>
            );
          }}
        />

        <Button
          type="submit"
          className="absolute right-3 top-3 h-8 w-8 p-0"
          variant="primary"
          disabled={!form.formState.isValid}
          isLoading={form.formState.isSubmitting}
        >
          {!form.formState.isSubmitting && <ArrowRight size={14} />}
        </Button>
      </form>
    );
  }
);

export { useChatMessageForm, type ChatMessageFormInputs, type ChatMessageFormOutputs };

export default ChatMessageForm;
