import {
  ChangeEvent,
  Children,
  ComponentPropsWithoutRef,
  ComponentRef,
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import usePrevious from "utils/customHooks/usePrevious";
import { useComposedRefs } from "utils/react-helpers/compose-refs";
import createComponentContext from "utils/react-helpers/createComponentContext";
import cn from "utils/tailwind/cn";

const TEXT_AREA_NAME = "TextArea";

export type TextAreaProps = Omit<ComponentPropsWithoutRef<"textarea">, "value" | "onChange"> & {
  value: string;
  onChange: (value: string, event: ChangeEvent<HTMLTextAreaElement>) => void;
};

type TextAreaContextValue = TextAreaProps & {
  isFocused: boolean;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
  shouldShrinkLabel: boolean;
  hasLabel: boolean;
};

const [TextAreaProvider, useTextAreaContext] =
  createComponentContext<TextAreaContextValue>(TEXT_AREA_NAME);

const TextArea = forwardRef<ComponentRef<"div">, TextAreaProps>(
  ({ children, className, ...props }, forwardedRef) => {
    const [isFocused, setIsFocused] = useState(false);
    const shouldShrinkLabel = Boolean(isFocused || props.value || props.placeholder);
    const [hasLabel, setHasLabel] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const ref = useComposedRefs(containerRef, forwardedRef);

    // This automagically detects whether or not there is a label component. If the number of
    // `children` changes, we re-check to see if a `label` exists.
    const prevNumChildren = usePrevious(Children.count(children));
    useEffect(() => {
      // Check for label
      if (containerRef.current) {
        const labels = containerRef.current.querySelectorAll("label");
        setHasLabel(labels.length > 0);
      }
    }, [children, prevNumChildren]);

    return (
      <TextAreaProvider
        value={{
          ...props,
          hasLabel,
          isFocused,
          setIsFocused,
          shouldShrinkLabel,
        }}
      >
        <div className={cn("relative w-full", className)} ref={ref}>
          {children}
        </div>
      </TextAreaProvider>
    );
  }
);

const TextAreaLabel = forwardRef<ComponentRef<"label">, ComponentPropsWithoutRef<"label">>(
  ({ className, children, ...props }, forwardedRef) => {
    const { shouldShrinkLabel } = useTextAreaContext();

    return (
      <label
        className={cn(
          "pointer-events-none absolute left-4 bg-white",
          "transition-all",
          "top-2 translate-y-1 text-sm text-grey-400",
          shouldShrinkLabel && "translate-y-0 text-xs font-bold text-grey-700",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </label>
    );
  }
);

/**
 * The underlying `textarea` input gets its props via context, but this component can be useful for customizing the underlying component's styles and for passing in a ref.
 */
const TextAreaInput = forwardRef<ComponentRef<"textarea">, { className?: string }>(
  ({ className }, forwardedRef) => {
    const {
      rows = 3,
      onChange,
      onFocus,
      onBlur,
      setIsFocused,
      hasLabel,
      isFocused: _isFocused,
      shouldShrinkLabel: _shouldShrinkLabel,
      ...textareaProps
    } = useTextAreaContext();

    return (
      <textarea
        rows={rows}
        className={cn(
          "w-full resize-none rounded-md border border-grey-200 px-4 pb-3 pt-6 text-sm placeholder:text-grey-400 focus:outline-focus",
          hasLabel ? "pt-6" : "pt-3",
          className
        )}
        onFocus={(e) => {
          setIsFocused(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          onBlur?.(e);
        }}
        onChange={(e) => onChange(e.target.value, e)}
        {...textareaProps}
        ref={forwardedRef}
      />
    );
  }
);

export default Object.assign(TextArea, {
  Label: TextAreaLabel,
  Input: TextAreaInput,
});
