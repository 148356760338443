import dayjs from "dayjs";
import React from "react";
import { ChatConfigRep } from "reps/chat/ChatConfigRep";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { Paragraph, Span } from "ui/typography";

type Props = {
  chatConfig: ChatConfigRep;
};

const ChatSettingsIsolatedDataViewItemText: React.FC<Props> = ({ chatConfig }) => {
  const at = chatConfig.lastRefreshAt ? dayjs(chatConfig.lastRefreshAt) : null;

  if (!at) {
    return (
      <Paragraph className="text-sm">
        The isolated data view has not been hydrated. This should normally only occur for a few
        minutes after configuring AI Analyst for the first time.
      </Paragraph>
    );
  }
  const diff = dayjs().diff(dayjs(at), "minute");
  return (
    <Paragraph className="text-sm">
      The isolated data view was last hydrated{" "}
      <Span className="font-bold">
        <ItemWithTooltip tooltip={<Paragraph>{at.toISOString()}</Paragraph>}>
          <Span>{diff} minutes ago</Span>
        </ItemWithTooltip>
      </Span>
      .
    </Paragraph>
  );
};

export default ChatSettingsIsolatedDataViewItemText;
