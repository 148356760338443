import { ErrorBoundary } from "@sentry/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import { AiChatUserAvatarByChatUserId } from "modules/chat/components/AiChatUserAvatar";
import AiChatYourDataIsStoredInHighbeamDisclaimer from "modules/chat/components/AiChatYourDataIsStoredInHighbeamDisclaimer";
import useCreateChatChannelMessageMutation from "modules/chat/mutations/useCreateChatChannelMessageMutation";
import { useChatChannel } from "modules/chat/queries/useChatChannel";
import { useChatChannelMessages } from "modules/chat/queries/useChatChannelMessages";
import { useCurrentChatUser } from "modules/chat/queries/useChatUsers";
import { useRandomSuggestedQuestions } from "modules/chat/queries/useSuggestedQuestions";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import ChatElement from "pages/ai-chat/ChatElement";
import { ComponentProps, FC, Suspense, useCallback, useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { ChannelRep } from "reps/chat/ChannelRep";
import { ChatUserRep } from "reps/chat/ChatUserRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import ChatMessage from "ui/chat/ChatMessage";
import ChatMessageForm, { useChatMessageForm } from "ui/chat/ChatMessageForm";
import { ChatMessageFormOutputs } from "ui/chat/ChatMessageForm/useChatMessageForm";
import ChatViewport from "ui/chat/ChatViewport";
import SidePanel from "ui/data-display/SidePanel";
import { notify } from "ui/feedback/Toast";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useMountEffect from "utils/customHooks/useMountEffect";
import useElementSize from "utils/device/useElementSize";
import cn from "utils/tailwind/cn";

import AiChatChannelPageChatViewport from "./AiChatChannelPageChatViewport";
import AiChatSuperuserDebugInfo from "./AiChatSuperuserDebugInfo";
import ChatMessageSidePanelContent from "./ChatMessageSidePanelContent";
import ChatSuggestionsEmbeddedDrawer from "./ChatSuggestionsEmbeddedDrawer";
import getIsAtBottom from "./getIsAtBottom";
import useChatPageKeyboardEvents from "./useChatPageKeyboardEvents";
import useUnreadMessagesCount from "./useUnreadMessagesCount";

const pxClasses = "px-4 @md:px-6 @lg:px-8";
const normalWidthClasses = "mx-auto w-full max-w-3xl";
const widerWidthClasses = "mx-auto w-full max-w-7xl";

type AiChatChannelPageContentProps = {
  chatChannel: ChannelRep;
  currentChatUser: ChatUserRep;
};

const AiChatChannelPageContent: FC<AiChatChannelPageContentProps> = ({
  chatChannel,
  currentChatUser,
}) => {
  const { width: containerWidth = 0, elementRef: containerRef } = useElementSize();

  const chatChannelMessages = useChatChannelMessages({
    channelId: chatChannel.id,
    // TODO(alex): HB-6780 Implement pagination.
    latestExclusive: null,
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const drawerContainerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const scrollToBottom = useCallback(() => {
    scrollContainerRef.current?.scrollTo({
      top: scrollContainerRef.current?.scrollHeight,
      behavior: "instant",
    });
  }, []);

  const { unreadMessagesCount, resetUnreadMessagesCount } = useUnreadMessagesCount({
    messages: chatChannelMessages,
    currentChatUserId: currentChatUser.id,
    scrollContainerElement: scrollContainerRef.current,
  });

  // Scroll to the most recent message on mount.
  useMountEffect(() => {
    scrollToBottom();
  });

  useEffect(() => {
    // Scroll to the most recent message when a new message comes in if scrolled is already at the bottom.
    if (scrollContainerRef.current) {
      const isAtBottom = getIsAtBottom(scrollContainerRef.current);
      if (isAtBottom) {
        scrollToBottom();
      }
    }
  }, [chatChannelMessages, scrollToBottom]);

  // Disable the form if superusering into another chat user's channel.
  const isSuperusering = useIsSuperusering();
  const isInOwnChannel = chatChannel.chatUserIds.includes(currentChatUser.id);
  const isSuperuseringInOtherUserChannel = isSuperusering && !isInOwnChannel;

  const form = useChatMessageForm({
    disabled: isSuperuseringInOtherUserChannel,
  });

  const { mutateAsync: createChatChannelMessage, isPending } = useCreateChatChannelMessageMutation({
    onSuccess: (_data) => {
      form.reset();
    },
  });

  const onSubmit = useCallback(
    async (data: ChatMessageFormOutputs) => {
      if (isPending) return;
      if (isSuperuseringInOtherUserChannel) {
        return notify("error", "Superusers cannot send chats in other users’ channels.");
      }

      await createChatChannelMessage({
        channelId: chatChannel.id,
        content: data.content,
        parentId: null,
      });
    },
    [chatChannel.id, isPending, createChatChannelMessage, isSuperuseringInOtherUserChannel]
  );

  useChatPageKeyboardEvents({
    drawerContainerRef,
    textAreaRef,
  });

  // TODO(alex): HB-6803 Update to use query param.
  const [sidePanelContentState, setSidePanelContentState] = useState<ComponentProps<
    typeof ChatMessageSidePanelContent
  > | null>(null);

  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(chatChannelMessages.length === 0);

  const suggestedQuestions = useRandomSuggestedQuestions(6);

  return (
    <SidePanel containerWidth={containerWidth}>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>{chatChannel.name}</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <div className="flex h-full w-full @container" ref={containerRef}>
        <div className="flex flex-1 flex-col">
          <AiChatChannelPageChatViewport>
            <ChatViewport.ScrollContainer
              className="relative"
              ref={scrollContainerRef}
              onScroll={(e) => {
                if (unreadMessagesCount > 0) {
                  const isAtBottom = getIsAtBottom(e.target as HTMLDivElement);
                  if (isAtBottom) {
                    resetUnreadMessagesCount();
                  }
                }
              }}
            >
              <div className="mx-auto pb-16 pt-7">
                {[...chatChannelMessages].reverse().map((message) => (
                  <div key={message.id}>
                    {message.contents.map((content, contentIndex) => {
                      const isLastContentBlock = contentIndex === message.contents.length - 1;
                      const showReplyInThreadButton =
                        isLastContentBlock && message.contents.length > 1;

                      const showAiChatSuperuserDebugInfo = isSuperusering && isLastContentBlock;

                      return (
                        <ChatMessage
                          key={contentIndex} // Index is static.
                          className="hover:bg-grey-50/50"
                        >
                          {content.elements.map((element, elementIndex) => (
                            <ChatMessage.Block
                              key={elementIndex} // Index is static.
                              avatar={
                                elementIndex === 0 ? (
                                  <AiChatUserAvatarByChatUserId chatUserId={content.senderId} />
                                ) : undefined
                              }
                              className={cn(normalWidthClasses, pxClasses)}
                            >
                              <ErrorBoundary fallback={<></>}>
                                {/* NB(alex): Hack to fix page from crashing. Will address this properly later in HB-6662 */}
                                <ChatElement element={element} />
                              </ErrorBoundary>
                            </ChatMessage.Block>
                          ))}

                          {message.contents.length === 1 && (
                            <ChatMessage.BlockLoading
                              className={cn(normalWidthClasses, pxClasses)}
                            />
                          )}

                          {showReplyInThreadButton && (
                            <ChatMessage.Block className={cn(normalWidthClasses, pxClasses)}>
                              <SidePanel.Trigger>
                                {({ isOpen, onOpenToggle }) => (
                                  <ChatMessage.ReplyInThreadButton
                                    replyCount={0}
                                    onClick={() => {
                                      // Set the panel's state to the current message if panel is being opened.
                                      setSidePanelContentState({
                                        messageId: message.id,
                                        channelId: chatChannel.id,
                                      });

                                      // Open the panel if it's closed.
                                      if (!isOpen) {
                                        onOpenToggle();
                                      } else {
                                        // Close the panel if clicking on this message's button and the panel is already open.
                                        if (sidePanelContentState?.messageId === message.id) {
                                          onOpenToggle();
                                        }
                                      }
                                    }}
                                  />
                                )}
                              </SidePanel.Trigger>
                            </ChatMessage.Block>
                          )}

                          {showAiChatSuperuserDebugInfo && (
                            <AiChatSuperuserDebugInfo messageId={message.id}>
                              <ChatMessage.Block className={cn(normalWidthClasses, pxClasses)}>
                                <AiChatSuperuserDebugInfo.Button />
                              </ChatMessage.Block>
                              <ChatMessage.Block className={cn(widerWidthClasses, pxClasses)}>
                                <AiChatSuperuserDebugInfo.Content />
                              </ChatMessage.Block>
                            </AiChatSuperuserDebugInfo>
                          )}
                        </ChatMessage>
                      );
                    })}
                  </div>
                ))}
              </div>
            </ChatViewport.ScrollContainer>

            <ChatViewport.InputContainer className="px-4 tablet:px-12" ref={inputContainerRef}>
              {Boolean(unreadMessagesCount) && (
                <ChatViewport.NewMessageButton
                  unreadMessagesCount={unreadMessagesCount}
                  onClick={() => {
                    scrollToBottom();
                  }}
                />
              )}

              <div className={cn("relative", normalWidthClasses)}>
                <ChatSuggestionsEmbeddedDrawer
                  style={{
                    ...(textAreaRef.current
                      ? { bottom: textAreaRef.current?.clientHeight + 1 }
                      : { display: "none" }),
                  }}
                  className={cn(
                    "z-[1]",
                    "shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),_0px_1px_2px_0px_rgba(0,0,0,0.06)]",
                    "max-h-none",
                    "mx-px"
                  )}
                  isOpen={isSuggestionsOpen}
                  onOpen={() => setIsSuggestionsOpen(true)}
                  onClose={() => setIsSuggestionsOpen(false)}
                  ref={drawerContainerRef}
                >
                  <ChatSuggestionsEmbeddedDrawer.Header
                    numSuggestions={suggestedQuestions.length}
                  />
                  <ChatSuggestionsEmbeddedDrawer.Body className="pb-3">
                    {suggestedQuestions.map((suggestedQuestion) => (
                      <ChatSuggestionsEmbeddedDrawer.SuggestionButton
                        key={suggestedQuestion.id}
                        markdown={suggestedQuestion.markdown}
                        onClick={() => {
                          onSubmit({ content: suggestedQuestion.plaintext });
                          setIsSuggestionsOpen(false);
                        }}
                      />
                    ))}
                  </ChatSuggestionsEmbeddedDrawer.Body>
                </ChatSuggestionsEmbeddedDrawer>

                <ChatMessageForm
                  className={cn("z-[2]", normalWidthClasses)}
                  onSubmit={onSubmit}
                  form={form}
                  textAreaRef={textAreaRef}
                />
              </div>

              <AiChatYourDataIsStoredInHighbeamDisclaimer className="mx-auto py-4" />
            </ChatViewport.InputContainer>
          </AiChatChannelPageChatViewport>
        </div>

        <SidePanel.Panel
          className="tablet:z-[3]" // Prevents textarea from appearing over the side panel, but on mobile, the low z-index causes the full drawer to disappear.
        >
          <Suspense fallback={null}>
            {sidePanelContentState && <ChatMessageSidePanelContent {...sidePanelContentState} />}
          </Suspense>
        </SidePanel.Panel>
      </div>
    </SidePanel>
  );
};

const AiChatChannelPage = () => {
  const currentChatUser = useCurrentChatUser();
  const { channelId } = useParams();
  const chatChannel = useChatChannel({ channelId: channelId! });
  const businessGuid = useBusinessGuid();
  const isSuperusering = useIsSuperusering();

  if (
    !chatChannel ||
    !currentChatUser ||
    // Prevents superusers from accidentally using other business chats when not superusering.
    (chatChannel.businessGuid !== businessGuid && !isSuperusering)
  ) {
    return <Navigate to="/ai" replace />;
  }

  return <AiChatChannelPageContent chatChannel={chatChannel} currentChatUser={currentChatUser} />;
};

export default AiChatChannelPage;
