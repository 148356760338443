import logoWithoutLabel from "assets/highbeam-logo-without-label.svg";
import TotalYieldInfoTooltipContent from "components/Accounts/InterestTier/TotalYieldEarnedTooltipContent";
import useBankAccountBalanceHistoryQuery from "modules/balance-history/queries/useBankAccountsBalanceHistoryQuery";
import bankAccountsQueryHooks from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import useBankAccountInterestYieldYtd from "modules/bank-accounts/queries/useBankAccountInterestYieldYtd";
import getTotalBalanceOfBankAccounts from "modules/bank-accounts/utils/getTotalBalanceOfBankAccounts";
import { useEffect, useState } from "react";
import colors from "styles/colors";
import CashDisplay from "ui/data-display/money/CashDisplay";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import ChartTools from "ui/data-visualization/ChartTools";
import DetailedAreaChart, { DetailedAreaChartDatum } from "ui/data-visualization/DetailedAreaChart";
import ButtonLink from "ui/inputs/ButtonLink";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Heading2 } from "ui/typography";
import Text from "ui/typography/Text";
import { useIsMobile } from "utils/device/useMediaQuery";
import {
  RelativeTimeframe,
  DEFAULT_RELATIVE_TIMEFRAME,
  timeframeToNumDaysBetweenTicks,
} from "utils/timeframe";

import styles from "./HomeBalance.module.scss";

const FundedAccount = () => {
  const [activeTick, setActiveTick] = useState<DetailedAreaChartDatum | null>(null);
  const [timeframe, setTimeframe] = useState<RelativeTimeframe>(DEFAULT_RELATIVE_TIMEFRAME);
  const numDaysBetweenTicks = timeframeToNumDaysBetweenTicks(timeframe);
  const { data: accountBalanceHistory, isFetching: isFetchingAccountBalanceHistory } =
    useBankAccountBalanceHistoryQuery(timeframe);
  const isMobile = useIsMobile();
  const [isAnimationActive, setIsAnimationActive] = useState(true);

  const interestYtdCents = useBankAccountInterestYieldYtd();
  const showInterestYtd = interestYtdCents > 0;

  const openBankAccounts = bankAccountsQueryHooks.useData({ status: "open" });
  const bankAccountsTotalBalance = getTotalBalanceOfBankAccounts(openBankAccounts);

  // Homegraph redrew itself when the timerange was adjusted from the dropdown in the homepage.
  // Using Profiler and WDYR (https://github.com/welldone-software/why-did-you-render), screenshot below,
  // we identified that it was happening cause of issues with the Animate component used by recharts
  // https://arc.net/e/79186D2C-087A-407E-9ECF-61E3BE4AAA95
  // This should be fixed later but for now this hack seems to work.
  useEffect(() => {
    const animationTimer = setTimeout(() => {
      setIsAnimationActive(false);
    }, 1500);
    return () => clearTimeout(animationTimer);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles["balance-container"]}>
          <Heading2>
            <img className={styles.logo} src={logoWithoutLabel} alt="Highbeam logo" />
            Highbeam balance
          </Heading2>
          <div className={styles["balance-container__balance"]}>
            <CashDisplay
              color={activeTick ? colors.purple[500] : colors.black}
              cents={
                activeTick
                  ? activeTick.amount
                  : accountBalanceHistory?.[accountBalanceHistory.length - 1]?.amount ||
                    bankAccountsTotalBalance
              }
            />
            <Text size={12} className={styles.text}>
              as of {activeTick ? activeTick.date : "today"}
            </Text>
          </div>

          {showInterestYtd && (
            <div className={styles.totalYieldContainer}>
              <div className={styles.totalYieldContainerHeader}>
                <Text size={14} weight="medium">
                  Total yield (YTD)
                </Text>
                <IconWithTooltip tooltip={<TotalYieldInfoTooltipContent />} />
              </div>

              <MoneyAmount
                color={"green"}
                cents={interestYtdCents}
                size={18}
                centsTextSize={14}
                weight="medium"
              />
            </div>
          )}

          <ButtonLink
            className={styles["balance-container__button"]}
            variant="tertiary"
            size="sm"
            to="/accounts/transactions"
          >
            See transactions
          </ButtonLink>
        </div>

        <div className={styles["chart-container"]}>
          <ChartTools>
            <ChartTools.RelativeTimeframeSelect value={timeframe} onChange={setTimeframe} />
          </ChartTools>
          {accountBalanceHistory && (
            <DetailedAreaChart
              data={accountBalanceHistory}
              ticks={accountBalanceHistory
                .map((datum) => datum.date)
                .filter((_, i) => {
                  const len = accountBalanceHistory.length;
                  return i === 0 || i === len - 1 || (len - i - 1) % numDaysBetweenTicks === 0;
                })}
              setActiveTick={setActiveTick}
              chartHeight={isMobile ? 180 : 350}
              isAnimationActive={isAnimationActive}
              isFetching={isFetchingAccountBalanceHistory}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FundedAccount;
