import { FileSearch } from "@phosphor-icons/react";
import { FC } from "react";
import { ChatUserRep } from "reps/chat/ChatUserRep";
import ChatAvatar from "ui/chat/ChatAvatar";
import getInitials from "utils/string/getInitials";

import { useChatUserQuery } from "../queries/useChatUsers";

export const AiChatAnalystAvatar = () => {
  return (
    <ChatAvatar className="border-green-200">
      <ChatAvatar.Fallback className="border-green-200 bg-linear-gradient-green-100">
        <FileSearch className="size-5 text-green-400" />
      </ChatAvatar.Fallback>
    </ChatAvatar>
  );
};

type Props = {
  chatUser: ChatUserRep;
};

const AiChatUserAvatar: FC<Props> = ({ chatUser }) => {
  if (chatUser.type === "Ai") {
    // NB(alex): We will add more variants here once applicable.
    return <AiChatAnalystAvatar />;
  } else {
    return (
      <ChatAvatar>
        <ChatAvatar.Fallback>{getInitials(chatUser.displayName)}</ChatAvatar.Fallback>
      </ChatAvatar>
    );
  }
};

export default AiChatUserAvatar;

// Wrappers

type AiChatUserAvatarByChatUserIdProps = {
  chatUserId: string;
};

export const AiChatUserAvatarByChatUserId: FC<AiChatUserAvatarByChatUserIdProps> = ({
  chatUserId,
}) => {
  const { data: chatUser } = useChatUserQuery(chatUserId);

  if (!chatUser) {
    return (
      <ChatAvatar>
        <ChatAvatar.Shimmer />
      </ChatAvatar>
    );
  }

  return <AiChatUserAvatar chatUser={chatUser} />;
};
