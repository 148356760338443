import HighbeamBaseApi from "api/HighbeamBaseApi";
import { MessageCreatorRep, MessageRep } from "reps/chat/MessageRep";

export default class MessageApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async searchChannel(channelId: string, latestExclusive: string | null): Promise<MessageRep[]> {
    const queryParams = new URLSearchParams({ channelId });
    if (latestExclusive) queryParams.set("latestExclusive", latestExclusive);
    const url = `/chat/messages?${queryParams}`;
    return (await this.api.get<MessageRep[]>(url))!;
  }

  async searchThread(channelId: string, parentId: string): Promise<MessageRep[]> {
    const queryParams = new URLSearchParams({ channelId, parentId });
    const url = `/chat/messages?${queryParams}`;
    return (await this.api.get<MessageRep[]>(url))!;
  }

  async create(creator: MessageCreatorRep): Promise<MessageRep> {
    const url = "/chat/messages";
    return (await this.api.post<MessageRep>(url, creator))!;
  }

  async delete(messageId: string): Promise<MessageRep> {
    const url = `/chat/messages/${messageId}`;
    return (await this.api.delete<MessageRep>(url))!;
  }
}
