import React from "react";
import { CategoryChatElementRep } from "reps/chat/ChatElementRep";

type Props = {
  element: CategoryChatElementRep;
};

const CategoryChatElement: React.FC<Props> = ({ element }) => {
  return <span className="font-bold">{element.humanReadable}</span>;
};

export default CategoryChatElement;
