import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

type Params = {
  channelId: string;
  parentMessageId: string;
};

const chatMessageThreadMessagesQueryHooks = makeQueryHooks({
  name: "chatMessageThreadMessages",
  useQueryVariables: (params: Params) => {
    return params;
  },
  useQueryFnMaker: ({ channelId, parentMessageId }) => {
    const highbeamApi = useHighbeamApi();
    return () => highbeamApi.message.searchThread(channelId, parentMessageId);
  },
});

export const useChatMessageThreadMessages = (params: Params) => {
  return chatMessageThreadMessagesQueryHooks.useData({ ...params, refetchInterval: 1000 });
};

export const useRefreshChatMessageThreadMessages = () => {
  return chatMessageThreadMessagesQueryHooks.useRefreshQueries();
};
