import { useSuspenseQueries } from "@tanstack/react-query";
import { FEATURE_FLAGS } from "flags";
import useApEmailAliases from "modules/ap-email-aliases/queries/useApEmailAliases";
import useShouldShowEmployeeView from "modules/bills/hooks/useShouldShowEmployeeView";
import assignedBillStatsQueryHooks from "modules/bills/queries/assignedBillStatsQueryHooks";
import billStatsQueryHooks from "modules/bills/queries/billStatsQueryHooks";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import { FC } from "react";
import CountBadge from "ui/data-display/CountBadge";
import BillPayIcon from "ui/icons/BillPayIcon";
import useIsAllowedToNavigateToBillPayRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToBillPayRoutes";
import useHasPermission from "utils/permissions/useHasPermission";

import MenuItem from "../MenuItem";
import NavFeatureStatusBadge from "../NavFeatureStatusBadge";
import { getCountBadgeColor } from "../utils";

type SharedProps = {
  onClose: () => void;
};

const BillPayMenuItemFullView: FC<SharedProps> = ({ onClose }) => {
  const [{ data: billStats }, { data: assignedBillStats }] = useSuspenseQueries({
    queries: [
      billStatsQueryHooks.useQueryOptions({}),
      assignedBillStatsQueryHooks.useQueryOptions({}),
    ],
  });

  const billsEmailInboxCount = billStats?.draft.totalCount;
  const allBillsCount = billStats
    ? billStats.open.totalCount + billStats.paid.totalCount + billStats.closed.totalCount
    : undefined;
  const assignedRequestedBillsCount = assignedBillStats?.requested.totalCount;

  return (
    <MenuItem
      text="Bill Pay"
      icon={<BillPayIcon />}
      path="/bills"
      onClick={onClose}
      badge={({ isActive }) => {
        const totalCount = (billsEmailInboxCount ?? 0) + (assignedRequestedBillsCount ?? 0);

        if (totalCount) {
          return <CountBadge count={totalCount} backgroundColor={getCountBadgeColor(isActive)} />;
        }

        if (allBillsCount === 0) {
          return <NavFeatureStatusBadge featureStatus="New" isActive={isActive} />;
        }
      }}
    />
  );
};

const BillPayMenuItemEmployeeView: FC<SharedProps> = ({ onClose }) => {
  const assignedBillStats = assignedBillStatsQueryHooks.useData({});
  const assignedRequestedBillsCount = assignedBillStats.requested.totalCount;

  return (
    <MenuItem
      text={"Bill approvals"}
      icon={<BillPayIcon />}
      path="/bills"
      onClick={onClose}
      badge={({ isActive }) => {
        if (assignedRequestedBillsCount) {
          return (
            <CountBadge
              count={assignedRequestedBillsCount}
              backgroundColor={getCountBadgeColor(isActive)}
            />
          );
        }
      }}
    />
  );
};

type Props = SharedProps;

const BillPayMenuRenderer: FC<Props> = ({ onClose }) => {
  const billPayEnabled = useFeatureFlag(FEATURE_FLAGS["bill-pay-ui"]);
  const isAllowedToNavigateToBillPayRoutes = useIsAllowedToNavigateToBillPayRoutes();
  const hasAccountsPayableReadPermission = useHasPermission("accountsPayableBill:read");

  const apEmailAliases = useApEmailAliases();
  const shouldShowBillsEmployeeView = useShouldShowEmployeeView();

  if (billPayEnabled && isAllowedToNavigateToBillPayRoutes) {
    if (shouldShowBillsEmployeeView) {
      if (apEmailAliases.length > 0) {
        return <BillPayMenuItemEmployeeView onClose={onClose} />;
      }
    } else if (hasAccountsPayableReadPermission) {
      return <BillPayMenuItemFullView onClose={onClose} />;
    }
  }

  return null;
};

export default BillPayMenuRenderer;
