import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { SuggestedQuestionRep } from "reps/chat/SuggestedQuestionRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

export const SUGGESTED_QUESTIONS_QUERY_KEY = "suggestedQuestions";

export const suggestedQuestionsQueryHooks = makeQueryHooks({
  name: "suggestedQuestions",
  useQueryVariables: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  useQueryFnMaker: ({ businessGuid }) => {
    const highbeamApi = useHighbeamApi();
    return () => highbeamApi.suggestedQuestion.listByBusiness(businessGuid);
  },
});

const useSuggestedQuestions = () => {
  return suggestedQuestionsQueryHooks.useData({});
};

export default useSuggestedQuestions;

// Hooks

export const selectRandomSuggestedQuestions =
  (count: number) => (suggestedQuestions: SuggestedQuestionRep[]) => {
    return suggestedQuestions.toSorted(() => Math.random() - 0.5).slice(0, count);
  };

export const useRandomSuggestedQuestions = (count: number): SuggestedQuestionRep[] => {
  return suggestedQuestionsQueryHooks.useDataRequired({
    select: selectRandomSuggestedQuestions(count),
  });
};
