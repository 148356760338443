// NB(alex): `leeway` determines how many px from the bottom we have to be to say we are "at the
// bottom". The main reason I added this is because when a new message comes in, it shifts the
// `clientHeight` before we check to see if we are already at the bottom, which results in never
// being at "the bottom". A potentially cleaner way to achieve this is to capture the additional
// height added by the incoming element and to incorporate that in our calculation, but this is my
// lazy solution that I think works perfectly fine for now.
const getIsAtBottom = (element: HTMLElement, leeway = 256) => {
  const { scrollTop, scrollHeight, clientHeight } = element;
  const isAtBottom =
    scrollHeight <= clientHeight || scrollTop + clientHeight >= scrollHeight - leeway;
  return isAtBottom;
};

export default getIsAtBottom;
