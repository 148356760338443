import { CalendarBlank } from "@phosphor-icons/react";
import { captureMessage } from "@sentry/react";
import * as echarts from "echarts";
import { get } from "lodash-es";
import { FC, useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import colors from "styles/colors";
import { useResizeObserver } from "utils/customHooks/useResizeObserver";
import cn from "utils/tailwind/cn";

import {
  checkIsValidYAxisValue,
  formatXAxisValue,
  formatYAxisValue,
} from "./chart-axis-formatter-utils";
import { CHART_COLORS, getChartColorByIndex, getChartColorHex } from "./chart-color-utils";
import ChartLegend from "./ChartLegend";
import ChartTooltip from "./ChartTooltip";

const X_AXIS_PADDING_TOP = 8;
const Y_AXIS_PADDING_RIGHT = 16;

type Props = {
  xAxisType: "YYYY-MM-DD";
  yAxisType: "money" | "number";
  xAxisData: string[];
  series: echarts.RegisteredSeriesOption["line"][];
  className?: string;
};

const LineChart: FC<Props> = ({ className, xAxisType, yAxisType, xAxisData, series }) => {
  const chartContainerRef = useRef(null); // Reference to the chart container
  const chartInstance = useRef<echarts.ECharts | null>(null); // Store the ECharts instance

  useEffect(() => {
    // Initialize the chart.
    if (chartContainerRef.current) {
      chartInstance.current = echarts.init(chartContainerRef.current);

      // Configure the chart.
      chartInstance.current.setOption<echarts.EChartsOption>({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: colors.grey[400],
              width: 1,
            },
            type: "line",
          },

          // Same styles as `shadow-xs`.
          shadowColor: "rgba(0, 0, 0, 0.07)",
          shadowOffsetX: 0,
          shadowOffsetY: 1,
          shadowBlur: 2,

          padding: [12, 16, 16, 16],
          borderColor: colors.grey[200],

          formatter: (params) => {
            const paramsAsArray = Array.isArray(params) ? params : [params];
            const title = formatXAxisValue({ value: paramsAsArray[0].name, type: xAxisType });

            // NB(alex): Needs to render a string. Total hack, but it works beautifully!
            return renderToString(
              <ChartTooltip>
                <ChartTooltip.Header>
                  <CalendarBlank />
                  {title}
                </ChartTooltip.Header>
                <ChartTooltip.Body>
                  {paramsAsArray.map((param, index) => {
                    if (!checkIsValidYAxisValue(param.value, yAxisType)) {
                      // NB(alex): Should never happen but this component isn't very mature yet so I might be missing something.
                      captureMessage("Invalid paramsAsArray yAxisValue", {
                        extra: { param },
                      });
                      return null;
                    }
                    return (
                      <ChartTooltip.Item
                        key={`${param.name}-${index}`}
                        color={CHART_COLORS[index % CHART_COLORS.length]}
                        label={param.seriesName}
                        value={formatYAxisValue({
                          value: param.value,
                          type: yAxisType,
                          compact: false,
                        })}
                      />
                    );
                  })}
                </ChartTooltip.Body>
              </ChartTooltip>
            );
          },

          position: function (_point, params, _el, _elRect, size) {
            const chart = chartInstance.current;
            if (!chart) return [0, 0];

            const [tooltipWidth] = size.contentSize;

            // Gets the x/y coordinates of the current data point (first item in the series).
            // NB(alex): I have no clue why this works but it seems to work perfectly.
            // This took a lot of chatgpt-ing to figure out. https://chatgpt.com/share/67aba7a2-1454-800e-b4bd-1b395a011b4b
            const xIndex = get(params, "[0].dataIndex", 0);
            const dataPointX = chart.convertToPixel({ seriesIndex: 0 }, [xIndex, 0])[0];
            const dataPointY = chart.convertToPixel({ seriesIndex: 0 }, [
              xIndex,
              get(params, "[0].value", 0),
            ])[1];

            // NB(alex): We want the tooltip on the left side of the x-axis grid line, but want to flip it if it's at the left-most edge of the chart.
            const shouldFlipTooltip = dataPointX < tooltipWidth + 64; // Added some arbitrary leeway.

            const SPACE_X = 12;
            const tooltipPositionX = shouldFlipTooltip
              ? dataPointX + SPACE_X
              : dataPointX - tooltipWidth - SPACE_X;

            const tooltipPositionY = dataPointY - size.contentSize[1] / 2;

            // Tooltip gets positioned next to the active point of the first line in the series.
            return [tooltipPositionX, tooltipPositionY];
          },
        },
        grid: {
          // NB(alex): The general philosophy is to fill the entire containing element and to let
          // external elements determine the chart's size instead of doing it here.
          left: Y_AXIS_PADDING_RIGHT + 4, // 4px is arbitrary for preventing wide labels from getting cut off. There might be a better way to dynamically fetch the y axis width.
          bottom: X_AXIS_PADDING_TOP,
          right: 4, // Right-most label and data point gets cut off. This may have to get increased if the last label has a lot of characters.
          top: 8, // Top label on y axis gets cut off.
          containLabel: true,
        },
        textStyle: {
          fontFamily: "Satoshi",
        },
        xAxis: {
          type: "category", // NB(alex): We currently only support "category", but we may want to expand functionality for the remaining types, "time" | "value" | "log".
          boundaryGap: false,
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: colors.grey[200],
            },
          },
          data: xAxisData,
          axisLabel: {
            color: colors.grey[600],
            formatter: (value) => {
              return formatXAxisValue({ value, type: xAxisType });
            },
            padding: [X_AXIS_PADDING_TOP, 0, 0, 0],
            hideOverlap: true,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: colors.grey[100],
              width: 1,
            },
          },
          axisLabel: {
            color: colors.grey[600],
            padding: [0, Y_AXIS_PADDING_RIGHT, 0, 0],
            formatter: (value) => {
              return formatYAxisValue({ value, type: yAxisType, compact: true });
            },
          },
        },
        series: series.map((serie, index) => ({
          color: getChartColorHex(getChartColorByIndex(index)),
          type: "line",
          ...serie,
        })),
      });

      // Cleanup on component unmount.
      return () => {
        if (chartInstance.current) {
          chartInstance.current.dispose();
        }
      };
    }
  }, [series, xAxisType, xAxisData, yAxisType]);

  // Resize the chart when the size changes.
  useResizeObserver(chartContainerRef, () => {
    requestAnimationFrame(() => {
      chartInstance.current?.resize();
    });
  });

  return (
    <div
      // Ensure the container has a size.
      className={cn("relative flex h-80 w-full flex-col", className)}
    >
      <ChartLegend>
        {series.map(({ name }, index) => {
          return (
            <ChartLegend.Item key={index} color={getChartColorByIndex(index)}>
              {name}
            </ChartLegend.Item>
          );
        })}
      </ChartLegend>

      <div ref={chartContainerRef} className="flex-1" />
    </div>
  );
};

export default LineChart;
