import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

export const chatUsersQueryHooks = makeQueryHooks({
  name: "chatUsers",
  useQueryVariables: () => {
    const businessGuid = useBusinessGuid();
    const userGuid = useUserGuid();
    return { businessGuid, userGuid };
  },
  useQueryFnMaker: ({ businessGuid, userGuid }) => {
    const highbeamApi = useHighbeamApi();

    return () => {
      return highbeamApi.chatUser.search(businessGuid, userGuid);
    };
  },
});

export const useChatUsers = () => {
  return chatUsersQueryHooks.useData({});
};

export const useCurrentChatUser = () => {
  const chatUsers = useChatUsers();
  const userGuid = useUserGuid();
  const currentChatUser = chatUsers?.find(
    (chatUser) =>
      (chatUser.type === "BusinessMember" || chatUser.type === "Highbeam") &&
      chatUser.userGuid === userGuid
  );
  return currentChatUser;
};

export const useCurrentChatUserOrThrow = () => {
  const currentChatUser = useCurrentChatUser();
  if (!currentChatUser) throw new RequiredButNotFoundError("Current chat user not found.");
  return currentChatUser;
};

export const useChatUserQuery = (chatUserId: string) => {
  return chatUsersQueryHooks.useQuery({
    select: (chatUsers) => chatUsers.find((user) => user.id === chatUserId),
  });
};
