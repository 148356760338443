import dayjs from "dayjs";
import React from "react";
import { MonthChatElementRep } from "reps/chat/ChatElementRep";

type Props = {
  element: MonthChatElementRep;
};

const MonthChatElement: React.FC<Props> = ({ element }) => {
  return <span>{dayjs(element.value).tz("America/New_York").format("MMMM YYYY")}</span>;
};

export default MonthChatElement;
