import colors from "styles/colors";
import variants from "utils/ts/variants";

export const CHART_COLORS = [
  "purple-500" as const,
  "green-400" as const,
  "orange-500" as const,
  "red-500" as const,
  "yellow-500" as const,
  "pink-500" as const,
];

export type ChartColor = (typeof CHART_COLORS)[number];

export const getChartColorByIndex = (index: number): ChartColor => {
  return CHART_COLORS[index % CHART_COLORS.length];
};

export const getChartBackgroundColorClass = (color: ChartColor): string => {
  return variants(color, {
    "purple-500": "bg-purple-500",
    "green-400": "bg-green-400",
    "orange-500": "bg-orange-500",
    "red-500": "bg-red-500",
    "yellow-500": "bg-yellow-500",
    "pink-500": "bg-pink-500",
  });
};

export const getChartTextColorClass = (color: ChartColor): string => {
  return variants(color, {
    "purple-500": "text-purple-500",
    "green-400": "text-green-400",
    "orange-500": "text-orange-500",
    "red-500": "text-red-500",
    "yellow-500": "text-yellow-500",
    "pink-500": "text-pink-500",
  });
};

export const getChartColorHex = (color: ChartColor): string => {
  return variants(color, {
    "purple-500": colors.purple[500],
    "green-400": colors.green[400],
    "orange-500": colors.orange[500],
    "red-500": colors.red[500],
    "yellow-500": colors.yellow[500],
    "pink-500": colors.pink[500],
  });
};
