import HighbeamBaseApi from "api/HighbeamBaseApi";
import { SuggestedQuestionRep } from "reps/chat/SuggestedQuestionRep";

export default class SuggestedQuestionApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async listByBusiness(businessGuid: string): Promise<SuggestedQuestionRep[]> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/chat/suggested-questions?${queryParams}`;
    return (await this.api.get<SuggestedQuestionRep[]>(url))!;
  }
}
