import { Authorization } from "@highbeam/unit-node-sdk";
import { useChatWidget } from "components/ChatWidget";
import TransactionFlexpaneDispute from "components/common/transaction-flexpane/TransactionFlexpaneDispute";
import dayjs from "dayjs";
import { FC } from "react";
import { formatBankingDate } from "utils/date";

type Props = {
  authorization: Authorization;
  onClose: () => void;
};

const CardAuthorizationFlexpaneDispute: FC<Props> = ({ authorization, onClose }) => {
  const chat = useChatWidget();

  const onContactSupport = () => {
    chat.message(
      `Hi, I have a question about my card authorization on ${formatBankingDate(dayjs(authorization.attributes.createdAt))}.`
    );
    onClose();
  };

  return <TransactionFlexpaneDispute onContactSupport={onContactSupport} />;
};

export default CardAuthorizationFlexpaneDispute;
