import { useQueryClient } from "@tanstack/react-query";
import { CHAT_CONFIGS_QUERY_KEY } from "modules/chat/queries/useChatConfig";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useHydrateIsolatedDataViewMutation = (
  additionalOptions: MutationAdditionalOptions<void, void>
) => {
  const queryClient = useQueryClient();
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useMutationWithDefaults(
    {
      mutationFn: async () => {
        await highbeamApi.chatRefresh.execute(businessGuid);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [CHAT_CONFIGS_QUERY_KEY, { businessGuid }],
        });
      },
    },
    additionalOptions
  );
};

export default useHydrateIsolatedDataViewMutation;
