import useBusinessApi from "modules/business/api/useBusinessApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import { ROOT_BUSINESSES_QUERY_KEY } from "./businessesQueryHooks";

type Params = {
  userGuid: string;
};

const businessesByMemberUserQueryHooks = makeQueryHooks({
  rootName: ROOT_BUSINESSES_QUERY_KEY,
  name: "businessesByMemberUser",
  useQueryVariables: ({ userGuid }: Params) => {
    return {
      userGuid: userGuid,
    };
  },
  useQueryFnMaker: ({ userGuid }) => {
    const businessApi = useBusinessApi();
    return () => {
      return businessApi.getByMemberUser(userGuid);
    };
  },
});

export default businessesByMemberUserQueryHooks;
