import { FEATURE_FLAGS } from "flags";
import useNonTerminatedCapitalAccounts from "modules/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";

const useIsCapitalOverviewPageEnabled = () => {
  const isCapitalOverviewPageFlagEnabled = useFeatureFlag(FEATURE_FLAGS["capital-overview-page"]);
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();

  return isCapitalOverviewPageFlagEnabled || nonTerminatedCapitalAccounts.length > 1;
};

export default useIsCapitalOverviewPageEnabled;
