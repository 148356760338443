import { FC } from "react";
import { ExceptionMessageLogRep } from "reps/chat/MessageLogRep";
import { Paragraph } from "ui/typography";

import MessageLogContainer from "./MessageLogContainer";
import MessageLogIndicator from "./MessageLogIndicator";
import MessageLogPre from "./MessageLogPre";
import { MessageLogRepDisplayProps } from "./MessageLogRepDisplay";

const ExceptionMessageLogRepDisplay: FC<MessageLogRepDisplayProps<ExceptionMessageLogRep>> = ({
  messageLog,
}) => {
  return (
    <MessageLogContainer className="bg-red-100">
      <div className="flex shrink-0 flex-row items-center gap-2">
        <MessageLogIndicator variant="angry" />
        <Paragraph>Exception</Paragraph>
      </div>
      <MessageLogPre value={messageLog.stackTrace} />
    </MessageLogContainer>
  );
};

export default ExceptionMessageLogRepDisplay;
