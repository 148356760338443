import { FC, ReactNode, useCallback, useId, useMemo, useRef, useState } from "react";

import {
  SidePanelEvents,
  SidePanelPositions,
  SidePanelProvider,
  SidePanelSide,
  useSidePanelContext,
} from "./context";
import SidePanelPanel from "./SidePanelPanel";
import SidePanelTrigger from "./SidePanelTrigger";

type Props = {
  side?: SidePanelSide;
  children?: ReactNode;
  defaultWidth?: number;
  positions?: SidePanelPositions;
  containerWidth: number;
} & SidePanelEvents;

const SidePanel: FC<Props> = ({
  side = "right",
  children,
  defaultWidth,
  positions: positionsProp,
  containerWidth,
  onOpenStart,
  onOpenEnd,
  onCloseStart,
  onCloseEnd,
}) => {
  const positions = useMemo(() => {
    const min = Math.min(containerWidth, positionsProp?.min ?? 256);
    const initial = Math.min(containerWidth, positionsProp?.initial ?? 448); // max-w-md
    const widest = positionsProp?.widest ?? Math.min(containerWidth, initial * 2);

    return {
      min,
      initial,
      widest,
    };
  }, [positionsProp, containerWidth]);

  const [isDragging, _setIsDragging] = useState(false);
  const setIsDragging = useCallback(
    (value: Parameters<typeof _setIsDragging>[0]) => {
      _setIsDragging(value);

      // Disable text selection when dragging.
      if (value) document.body.style.userSelect = "none";
      else document.body.style.userSelect = "auto";
    },
    [_setIsDragging]
  );

  const [transitionEnabled, setTransitionEnabled] = useState(false);
  const [width, setWidth] = useState(defaultWidth ?? 0);
  const [showChildren, setShowChildren] = useState(false);

  const isOpen = useMemo(() => width > 0, [width]);

  const onOpenToggle = useCallback(() => {
    setWidth(isOpen ? 0 : positions.initial);
    setTransitionEnabled(true);
  }, [isOpen, setWidth, positions.initial]);

  const panelId = useId();
  const panelRef = useRef<HTMLDivElement>(null);
  const resizerRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  return (
    <SidePanelProvider
      value={{
        // State
        isDragging,
        setIsDragging,
        transitionEnabled,
        setTransitionEnabled,
        showChildren,
        setShowChildren,
        width,
        setWidth,
        // Events
        onOpenStart,
        onOpenEnd,
        onCloseStart,
        onCloseEnd,
        // Configs
        positions,
        containerWidth,
        side,
        // Helpers
        isOpen,
        onOpenToggle,
        // Refs & ids
        panelId,
        panelRef,
        resizerRef,
        triggerRef,
      }}
    >
      {children}
    </SidePanelProvider>
  );
};

export { useSidePanelContext };

export default Object.assign(SidePanel, {
  Panel: SidePanelPanel,
  Trigger: SidePanelTrigger,
});
