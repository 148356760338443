import { configureScope } from "@sentry/react";
import env from "env";
import { FEATURE_FLAGS, FEATURE_VALUES } from "flags";
import * as LDClient from "launchdarkly-js-client-sdk";
import useBusiness from "modules/business/queries/useBusiness";
import SuperuserBanner from "modules/superuser/SuperuserBanner/SuperuserBanner";
import useUser from "modules/user/queries/useUser";
import React, { ReactNode, useEffect, useState } from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import isSuperuseringState, { useIsSuperusering } from "state/auth/isSuperusering";
import featureFlagsState from "state/featureFlags";
import featureValuesState from "state/featureValues";
import { notify } from "ui/feedback/Toast";
import useSegment from "utils/customHooks/useSegment";

type Props = {
  readonly children?: ReactNode;
  readonly fallback: ReactNode;
};

const AuthenticatedRootLayout: React.FC<Props> = ({ fallback, children }) => {
  const user = useUser();
  const business = useBusiness();

  const { segmentIdentify } = useSegment();

  const isSuperusering = useIsSuperusering();
  const resetIsSuperusering = useResetRecoilState(isSuperuseringState);

  const [hasLoadedFlags, setHasLoadedFlags] = useState(false);

  const setFeatureFlags = useSetRecoilState(featureFlagsState);
  const setFeatureValues = useSetRecoilState(featureValuesState);

  const useFallback = !user || !business || !hasLoadedFlags;

  // NB(alex): `hasLoadedFlags` blocks content from rendering so if nothing has loaded for 2 seconds, we just go ahead mark it as `true`, in case LaunchDarkly isn't responding.
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!hasLoadedFlags) {
        setHasLoadedFlags(true);
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, [hasLoadedFlags]);

  useEffect(() => {
    (async () => {
      // NB(alex): Hack for resetting superuser if business doesn't exist.
      // This usually happens when switching from prod to staging environment variables.
      if (!business && isSuperusering) {
        resetIsSuperusering();
        notify("info", "Business not found while superusering.");
      }

      const fetchFeatureFlags = async () => {
        const ldUser = {
          key: business.guid,
          name: business.internalName || business.guid,
        };
        const ldClient = LDClient.initialize(env.LAUNCH_DARKLY_CLIENT_ID, ldUser);
        ldClient.on("ready", () => {
          setFeatureFlags(
            Object.values(FEATURE_FLAGS).reduce(
              (flagObj, flag) => ({ ...flagObj, [flag]: ldClient.variation(flag, false) }),
              {}
            )
          );

          setFeatureValues(
            Object.values(FEATURE_VALUES).reduce(
              (flagObj, flag) => ({ ...flagObj, [flag]: ldClient.variation(flag, "") }),
              {}
            )
          );

          setHasLoadedFlags(true);
        });
      };

      const configureSentryUser = async () => {
        configureScope((scope) => {
          scope.setTags({
            "user.email": user.emailAddress,
            "business.displayName": business.internalName,
          });
          scope.setUser({
            id: user.guid,
            name: user.displayName,
            email: user.emailAddress,
            businessGuid: business.guid,
            businessName: business.internalName,
          });
        });
      };

      fetchFeatureFlags();
      if (env.SENTRY_ENABLED) {
        configureSentryUser();
      }
      if (env.SEGMENT_ENABLED) {
        segmentIdentify();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, business, isSuperusering]);

  if (useFallback) return <>{fallback}</>;

  return (
    <>
      <SuperuserBanner />
      {children}
    </>
  );
};

export default AuthenticatedRootLayout;
