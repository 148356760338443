/* eslint sort-keys: ["error", "asc", { "natural": true }] */

/**
 * Boolean, on/off flags
 */
const PERMANENT_FEATURE_FLAGS = {
  "allow-store-disconnection-in-settings": "allow-store-disconnection-in-settings",
  "close-bank-account": "close-bank-account",
  "credit-allow-skip-stores": "credit-allow-skip-stores",
  "require-store-connection-in-onboarding": "require-store-connection-in-onboarding",
  "settings-allow-accounting-software-connection": "settings-allow-accounting-software-connection",
} as const;

const TEMPORARY_FEATURE_FLAGS = {
  "archived-card-details": "archived-card-details",
  "bill-pay-manual-bill-creation": "bill-pay-manual-bill-creation",
  "bill-pay-ui": "bill-pay-ui",
  "capital-allow-ca-credit-applications": "capital-allow-ca-credit-applications",
  "capital-overview-page": "capital-overview-page",
  "capital-unaccepted-agreement-flow": "capital-unaccepted-agreement-flow",
  "cash-flow-view": "cash-flow-view",
  "checks-allow-invoice-upload": "checks-allow-invoice-upload",
  "checks-skip-endorsement-step": "checks-skip-endorsement-step",
  "credit-application-requirements-modal": "credit-application-requirements-modal",
  "duplicate-bill-banner-v2": "duplicate-bill-banner-v2",
  "failed-debits-ui": "failed-debits-ui",
  "hide-connected-stores": "hide-connected-stores",
  "include-adjustment-transactions-as-interest": "include-adjustment-transactions-as-interest",
  "payment-flow-allow-bank-account-update": "payment-flow-allow-bank-account-update",
  "prevent-all-deposit-account-creation": "prevent-all-deposit-account-creation",
  "prevent-daca-deposit-account-creation": "prevent-daca-deposit-account-creation",
  "prevent-interest-tier-switching": "prevent-interest-tier-switching",
  "same-day-ach-domestic-ach": "same-day-ach-domestic-ach",
  "verify-local-gbp-send-money": "verify-local-gbp-send-money",
  "vgsi-new-terms": "vgsi-new-terms",
} as const;

export const FEATURE_FLAGS = {
  ...PERMANENT_FEATURE_FLAGS,
  ...TEMPORARY_FEATURE_FLAGS,
};

/**
 * String values
 */
export const FEATURE_VALUES = {
  example: "replace-me-this-is-here-for-type-safety",
} as const;
