import React from "react";
import { BulletedListChatElementRep } from "reps/chat/ChatElementRep";

import ParagraphChatElement from "./ParagraphChatElement";

type Props = {
  element: BulletedListChatElementRep;
};

const BulletedListChatElement: React.FC<Props> = ({ element }) => {
  return (
    <ul className="list-disc pl-7">
      {element.items.map((item, i) => (
        <li key={i}>
          <ParagraphChatElement element={item} />
        </li>
      ))}
    </ul>
  );
};

export default BulletedListChatElement;
