import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import { ROOT_UNIT_CHECK_DEPOSIT_QUERY_KEY } from "./useRefreshUnitCheckDepositQueries";

type Params = {
  checkDepositId: string;
  include?: string;
};

export const unitCheckDepositQueryHooks = makeQueryHooks({
  rootName: ROOT_UNIT_CHECK_DEPOSIT_QUERY_KEY,
  name: "unitCheckDeposit",
  useQueryVariables: (params: Params) => params,
  useQueryFnMaker: ({ checkDepositId, include }) => {
    const unitApi = useUnitApi();
    return () => {
      return unitApi.checkDeposits.get(checkDepositId, include);
    };
  },
});

const useUnitCoCheckDeposit = (params: Params) => {
  return unitCheckDepositQueryHooks.useData(params).data;
};

export default useUnitCoCheckDeposit;
