import { FC, useState } from "react";
import { Control, useController } from "react-hook-form";
import DropdownV2, { convertStringValueToDropdownOption } from "ui/inputs/DropdownV2";

import useCounterpartySuggestions from "./data/useCounterpartySuggestions";
import { UpdateCounterpartyAliasFormInputs } from "./useUpdateCounterpartyAliasForm";

type Props = {
  control: Control<UpdateCounterpartyAliasFormInputs>;
  direction: "money-in" | "money-out";
};

const UpdateCounterpartyAliasDropdown: FC<Props> = ({ control, direction }) => {
  const counterpartySuggestions = useCounterpartySuggestions();

  const {
    field: { value, onChange, ...field },
  } = useController<UpdateCounterpartyAliasFormInputs>({
    name: "counterpartyName",
    control: control,
  });

  const [inputValue, setInputValue] = useState("");

  const counterpartySuggestionsAsDropdownOptions = counterpartySuggestions.map(
    convertStringValueToDropdownOption
  );

  return (
    <DropdownV2
      inputValue={inputValue}
      onInputChange={(newValue) => setInputValue(newValue)}
      options={counterpartySuggestionsAsDropdownOptions}
      label={`${direction === "money-in" ? "Source" : "Vendor"} name`}
      isClearable
      createOptionPosition={inputValue ? "first" : "last"}
      value={convertStringValueToDropdownOption(value)}
      onChange={(newValue) => {
        onChange(newValue?.value ?? "");
      }}
      onCreateOption={(newValue) => {
        onChange(newValue);
      }}
      isValidNewOption={(inputValue) => {
        // NB(alex): This fixes a weird bug where the "Create new option" button doesn't show up when there are too many matches. e.g. I would enter "Highbeam", and the option would go away. We always want to show it.
        return !counterpartySuggestions.some((suggestion) => suggestion === inputValue);
      }}
      {...field}
    />
  );
};

export default UpdateCounterpartyAliasDropdown;
