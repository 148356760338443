import { FC } from "react";
import ScrollWithInsetShadow from "ui/data-display/ScrollWithInsetShadow";

type Props = {
  value: unknown;
};

const MessageLogPre: FC<Props> = ({ value }) => {
  return (
    <ScrollWithInsetShadow className="max-h-36 overflow-y-auto">
      <pre className="font-mono select-all whitespace-pre-wrap break-words text-sm">
        {JSON.stringify(value, null, 2)}
      </pre>
    </ScrollWithInsetShadow>
  );
};

export default MessageLogPre;
