import MessageLogIndicator from "modules/chat/components/MessageLogIndicator";
import { FC } from "react";
import { LlmRequestMessageLogRep } from "reps/chat/MessageLogRep";
import { Paragraph } from "ui/typography";

import MessageLogContainer from "./MessageLogContainer";
import MessageLogPre from "./MessageLogPre";
import { MessageLogRepDisplayProps } from "./MessageLogRepDisplay";

const LlmRequestMessageLogRepDisplay: FC<MessageLogRepDisplayProps<LlmRequestMessageLogRep>> = ({
  messageLog,
}) => {
  return (
    <MessageLogContainer className="flex flex-row gap-2 bg-orange-100">
      <div className="flex shrink-0 flex-row items-center gap-2">
        <MessageLogIndicator variant={indicatorVariant(messageLog.milliseconds)} />
        <Paragraph>
          <span className="select-all font-monospace">{messageLog.name ?? "null"}</span> LLM request
          <br />
          using <span className="select-all font-monospace">{messageLog.model}</span>
          <br />
          took{" "}
          <span className="font-monospace">
            <span className="select-all">{(messageLog.milliseconds / 1000).toFixed(1)}</span>
            {"\u200B"}s
          </span>
          <br />
          <span className="select-all font-monospace">{messageLog.status}</span>
        </Paragraph>
      </div>
      <div className="flex flex-col gap-2">
        {messageLog.request && <MessageLogPre value={messageLog.request} />}
        {messageLog.response && <MessageLogPre value={messageLog.response} />}
      </div>
    </MessageLogContainer>
  );
};

export default LlmRequestMessageLogRepDisplay;

const indicatorVariant = (milliseconds: number) => {
  if (milliseconds < 4_000) return "happy";
  if (milliseconds < 40_000) return "meh";
  return "sad";
};
