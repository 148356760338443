import { useChatWidget } from "components/ChatWidget";
import TransactionFlexpaneDispute from "components/common/transaction-flexpane/TransactionFlexpaneDispute";
import dayjs from "dayjs";
import useUnitCoCheckDeposit from "modules/unit-co-check-deposits/queries/useUnitCheckDeposit";
import { FC } from "react";
import { formatBankingDate } from "utils/date";

type Props = {
  checkDepositId: string;
  onClose: () => void;
};

const CheckDepositFlexpaneTransactionDispute: FC<Props> = ({ checkDepositId, onClose }) => {
  const checkDeposit = useUnitCoCheckDeposit({ checkDepositId });

  const chat = useChatWidget();

  const onContactSupport = () => {
    chat.message(
      `Hi, I have a question about my check deposit on ${formatBankingDate(dayjs(checkDeposit.attributes.createdAt))}.`
    );
    onClose();
  };

  return (
    <TransactionFlexpaneDispute
      title="Check stuck on processing for too long?"
      description="Check deposits take 6-10 business days to arrive on average. If it is taking too long, contact us."
      onContactSupport={onContactSupport}
    />
  );
};

export default CheckDepositFlexpaneTransactionDispute;
