import MessageLogIndicator from "modules/chat/components/MessageLogIndicator";
import { FC } from "react";
import { EndToEndTimeMessageLogRep } from "reps/chat/MessageLogRep";
import { Paragraph } from "ui/typography";

import MessageLogContainer from "./MessageLogContainer";
import { MessageLogRepDisplayProps } from "./MessageLogRepDisplay";

const EndToEndTimeMessageLogRepDisplay: FC<
  MessageLogRepDisplayProps<EndToEndTimeMessageLogRep>
> = ({ messageLog }) => {
  return (
    <MessageLogContainer className="flex flex-row items-center gap-2 bg-purple-100">
      <MessageLogIndicator variant={indicatorVariant(messageLog.milliseconds)} />
      <Paragraph>
        Took{" "}
        <span className="font-monospace">
          <span className="select-all">{Math.round(messageLog.milliseconds / 1000)}</span>
          {"\u200B"}s
        </span>{" "}
        in total
      </Paragraph>
    </MessageLogContainer>
  );
};

export default EndToEndTimeMessageLogRepDisplay;

const indicatorVariant = (milliseconds: number) => {
  if (milliseconds < 15_000) return "happy";
  if (milliseconds < 45_000) return "meh";
  return "sad";
};
