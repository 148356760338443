import env from "env";

import CardApi from "./CardApi";
import ChargeCardAccountApi from "./ChargeCardAccountApi";
import ChargeCardOfferApi from "./ChargeCardOfferApi";
import ChargeCardRepaymentApi from "./ChargeCardRepaymentApi";
import ChannelApi from "./chat/ChannelApi";
import ChatConfigApi from "./chat/ChatConfigApi";
import ChatRefreshApi from "./chat/ChatRefreshApi";
import ChatUserApi from "./chat/ChatUserApi";
import KnowledgeBaseItemApi from "./chat/KnowledgeBaseItemApi";
import MessageApi from "./chat/MessageApi";
import MessageProfileApi from "./chat/MessageProfileApi";
import SuggestedQuestionApi from "./chat/SuggestedQuestionApi";
import CounterpartyAliasManagementApi from "./CounterpartyAliasManagementApi";
import CounterpartyReportApi from "./CounterpartyReportApi";
import CreditApplicationApi from "./CreditApplicationApi";
import CreditComparisonApi from "./CreditComparisonApi";
import DailyPlReportApi from "./DailyPlReportApi";
import DailyShopifySummaryApi from "./DailyShopifySummaryApi";
import DocumentsApi from "./DocumentsApi";
import DocumentV2ActionApi from "./DocumentV2ActionApi";
import DocumentV2Api from "./DocumentV2Api";
import GeneralPaymentMetadataApi from "./GeneralPaymentMetadataApi";
import HighbeamBaseApi from "./HighbeamBaseApi";
import CategorizationRuleApi from "./Insights/CategorizationRuleApi";
import CategorizedResourceApi from "./Insights/CategorizedResourceApi";
import SubcategoryApi from "./Insights/SubcategoryApi";
import InsightsCategoryApi from "./InsightsCategoryApi";
import InsightsSheetApi from "./InsightsSheetApi";
import InsightsSyncApi from "./InsightsSyncApi";
import InstitutionApi from "./InstitutionApi";
import IntercomApi from "./IntercomApi";
import InternationalBankAccountApi from "./InternationalBankAccountApi";
import LeadApi from "./LeadApi";
import LineOfCreditAgreementApi from "./LineOfCreditAgreementApi";
import LineOfCreditApi from "./LineOfCreditApi";
import LineOfCreditInterestFeeApi from "./LineOfCreditInterestFeeApi";
import LineOfCreditTransactionsApi from "./LineOfCreditTransactionsApi";
import PayeeApi from "./PayeeApi";
import PayeeValidatorApi from "./PayeeValidatorApi";
import PaymentActionApi from "./PaymentActionApi";
import PaymentApi from "./PaymentApi";
import PaymentApiV2 from "./PaymentApiV2";
import InternationalPaymentDetailsApi from "./PaymentDetailsApi";
import PaymentSwitcherAutoPaymentApi from "./PaymentSwitcherAutoPaymentApi";
import PlaidApi from "./PlaidApi";
import ReferralLinkApi from "./ReferralLinkApi";
import RutterBalanceApi from "./RutterBalanceApi";
import RutterConnectionApi from "./RutterConnectionApi";
import RutterPayoutApi from "./RutterPayoutApi";
import ShopifyBalanceApi from "./ShopifyBalanceApi";
import ShopifyConnectionApi from "./ShopifyConnectionApi";
import ShopifyPayoutApi from "./ShopifyPayoutApi";
import TransactionApi from "./TransactionApi";
import TransactionCategorizationMatcherApi from "./TransactionCategorizationMatcherApi";
import UnitCoCustomerTokenApi from "./UnitCoCustomerTokenApi";
import UserApi from "./UserApi";
import UserExistenceApi from "./UserExistenceApi";
import UserInvitationActionApi from "./UserInvitationActionApi";
import UserInvitationApi from "./UserInvitationApi";
import UserNotificationSettingsApi from "./UserNotificationSettingsApi";
import UserRoleApi from "./UserRoleApi";
import UserRoleMembershipApi from "./UserRoleMembershipApi";

/**
 * Enables interaction with the Highbeam backend. In order to avoid an excessively large file, the API
 * interaction is broken down into delegate APIs that all use a common shared base.
 */
export default class HighbeamApi {
  readonly card: CardApi;
  readonly categorizationRule: CategorizationRuleApi;
  readonly categorizedResource: CategorizedResourceApi;
  readonly channelApi: ChannelApi;
  readonly chargeCardAccount: ChargeCardAccountApi;
  readonly chargeCardOffer: ChargeCardOfferApi;
  readonly chargeCardRepayment: ChargeCardRepaymentApi;
  readonly chatConfig: ChatConfigApi;
  readonly chatRefresh: ChatRefreshApi;
  readonly chatUser: ChatUserApi;
  readonly counterpartyAliasManagement: CounterpartyAliasManagementApi;
  readonly counterpartyReport: CounterpartyReportApi;
  readonly creditApplication: CreditApplicationApi;
  readonly creditComparison: CreditComparisonApi;
  readonly dailyPlReport: DailyPlReportApi;
  readonly dailyShopifySummary: DailyShopifySummaryApi;
  readonly documentV2: DocumentV2Api;
  readonly documentV2Action: DocumentV2ActionApi;
  readonly documents: DocumentsApi;
  readonly generalPaymentMetadata: GeneralPaymentMetadataApi;
  readonly insightsCategory: InsightsCategoryApi;
  readonly insightsSheet: InsightsSheetApi;
  readonly insightsSync: InsightsSyncApi;
  readonly institution: InstitutionApi;
  readonly intercom: IntercomApi;
  readonly internationalBankAccount: InternationalBankAccountApi;
  readonly internationalPaymentDetails: InternationalPaymentDetailsApi;
  readonly knowledgeBaseItem: KnowledgeBaseItemApi;
  readonly lead: LeadApi;
  readonly lineOfCredit: LineOfCreditApi;
  readonly lineOfCreditAgreement: LineOfCreditAgreementApi;
  readonly lineOfCreditInterestFee: LineOfCreditInterestFeeApi;
  readonly lineOfCreditTransactions: LineOfCreditTransactionsApi;
  readonly message: MessageApi;
  readonly messageProfile: MessageProfileApi;
  readonly payee: PayeeApi;
  readonly payeeValidator: PayeeValidatorApi;
  readonly payment: PaymentApi;
  readonly paymentAction: PaymentActionApi;
  readonly paymentSwitcherAutoPayment: PaymentSwitcherAutoPaymentApi;
  readonly paymentV2: PaymentApiV2;
  readonly plaid: PlaidApi;
  readonly referralLink: ReferralLinkApi;
  readonly rutterBalance: RutterBalanceApi;
  readonly rutterConnection: RutterConnectionApi;
  readonly rutterPayout: RutterPayoutApi;
  readonly shopifyBalance: ShopifyBalanceApi;
  readonly shopifyConnection: ShopifyConnectionApi;
  readonly shopifyPayouts: ShopifyPayoutApi;
  readonly subcategories: SubcategoryApi;
  readonly suggestedQuestion: SuggestedQuestionApi;
  readonly transaction: TransactionApi;
  readonly transactionCategorizationMatcher: TransactionCategorizationMatcherApi;
  readonly unitCoCustomerToken: UnitCoCustomerTokenApi;
  readonly user: UserApi;
  readonly userExistence: UserExistenceApi;
  readonly userInvitationAction: UserInvitationActionApi;
  readonly userInvitation: UserInvitationApi;
  readonly userNotificationSettings: UserNotificationSettingsApi;
  readonly userRole: UserRoleApi;
  readonly userRoleMembership: UserRoleMembershipApi;

  constructor(getJwt: () => Promise<string | undefined>) {
    const v1Api = new HighbeamBaseApi(env.HIGHBEAM_API_ORIGIN_V1, getJwt);
    const v2Api = new HighbeamBaseApi(env.HIGHBEAM_API_ORIGIN_V2, getJwt);

    // TODO: Generate the following from backend code
    this.card = new CardApi(v1Api);
    this.categorizationRule = new CategorizationRuleApi(v1Api);
    this.categorizedResource = new CategorizedResourceApi(v1Api);
    this.channelApi = new ChannelApi(v2Api);
    this.chargeCardAccount = new ChargeCardAccountApi(v1Api);
    this.chargeCardOffer = new ChargeCardOfferApi(v1Api);
    this.chargeCardRepayment = new ChargeCardRepaymentApi(v1Api);
    this.chatConfig = new ChatConfigApi(v2Api);
    this.chatRefresh = new ChatRefreshApi(v2Api);
    this.chatUser = new ChatUserApi(v2Api);
    this.counterpartyAliasManagement = new CounterpartyAliasManagementApi(v2Api);
    this.counterpartyReport = new CounterpartyReportApi(v2Api);
    this.creditApplication = new CreditApplicationApi(v1Api);
    this.creditComparison = new CreditComparisonApi(v1Api);
    this.dailyPlReport = new DailyPlReportApi(v2Api);
    this.dailyShopifySummary = new DailyShopifySummaryApi(v1Api);
    this.documentV2 = new DocumentV2Api(v2Api);
    this.documentV2Action = new DocumentV2ActionApi(v2Api);
    this.documents = new DocumentsApi(v1Api);
    this.generalPaymentMetadata = new GeneralPaymentMetadataApi(v1Api);
    this.insightsCategory = new InsightsCategoryApi(v2Api);
    this.insightsSheet = new InsightsSheetApi(v2Api);
    this.insightsSync = new InsightsSyncApi(v2Api);
    this.institution = new InstitutionApi(v1Api);
    this.intercom = new IntercomApi(v1Api);
    this.internationalBankAccount = new InternationalBankAccountApi(v1Api);
    this.internationalPaymentDetails = new InternationalPaymentDetailsApi(v1Api);
    this.knowledgeBaseItem = new KnowledgeBaseItemApi(v2Api);
    this.lead = new LeadApi(v1Api);
    this.lineOfCredit = new LineOfCreditApi(v1Api);
    this.lineOfCreditAgreement = new LineOfCreditAgreementApi(v1Api);
    this.lineOfCreditInterestFee = new LineOfCreditInterestFeeApi(v1Api);
    this.lineOfCreditTransactions = new LineOfCreditTransactionsApi(v1Api);
    this.message = new MessageApi(v2Api);
    this.messageProfile = new MessageProfileApi(v2Api);
    this.payee = new PayeeApi(v1Api);
    this.payeeValidator = new PayeeValidatorApi(v1Api);
    this.payment = new PaymentApi(v1Api);
    this.paymentAction = new PaymentActionApi(v1Api);
    this.paymentSwitcherAutoPayment = new PaymentSwitcherAutoPaymentApi(v2Api);
    this.paymentV2 = new PaymentApiV2(v1Api);
    this.plaid = new PlaidApi(v1Api);
    this.referralLink = new ReferralLinkApi(v1Api);
    this.rutterBalance = new RutterBalanceApi(v1Api);
    this.rutterConnection = new RutterConnectionApi(v1Api);
    this.rutterPayout = new RutterPayoutApi(v1Api);
    this.shopifyBalance = new ShopifyBalanceApi(v1Api);
    this.shopifyConnection = new ShopifyConnectionApi(v1Api);
    this.shopifyPayouts = new ShopifyPayoutApi(v1Api);
    this.subcategories = new SubcategoryApi(v1Api);
    this.suggestedQuestion = new SuggestedQuestionApi(v2Api);
    this.transaction = new TransactionApi(v1Api);
    this.transactionCategorizationMatcher = new TransactionCategorizationMatcherApi(v2Api);
    this.unitCoCustomerToken = new UnitCoCustomerTokenApi(v1Api);
    this.user = new UserApi(v1Api);
    this.userExistence = new UserExistenceApi(v1Api);
    this.userInvitationAction = new UserInvitationActionApi(v1Api);
    this.userInvitation = new UserInvitationApi(v1Api);
    this.userNotificationSettings = new UserNotificationSettingsApi(v1Api);
    this.userRole = new UserRoleApi(v1Api);
    this.userRoleMembership = new UserRoleMembershipApi(v1Api);
  }
}
