import { useSuspenseQuery } from "@tanstack/react-query";
import { FEATURE_FLAGS } from "flags";
import DashboardPage from "layouts/DashboardPage";
import useRutterBalancesQueryOptions from "modules/connected-stores/queries/useRutterBalancesQueryOptions";
import useRutterConnectionsQueryOptions from "modules/connected-stores/queries/useRutterConnectionsQueryOptions";
import useShopifyBalancesQueryOptions from "modules/connected-stores/queries/useShopifyBalancesQueryOptions";
import useShopifyConnectionsQueryOptions from "modules/connected-stores/queries/useShopifyConnectionsQueryOptions";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import { formatBalances } from "utils/balances";
import { isRutterCommercePlatformOrPaymentProcessor } from "utils/rutter/typeguards";

import StoreAccountsTable from "../../components/StoreAccountsTable";

const useStoreBalances = () => {
  const { data: shopifyConnections } = useSuspenseQuery(useShopifyConnectionsQueryOptions());
  const { data: shopifyBalances } = useSuspenseQuery(useShopifyBalancesQueryOptions());
  const { data: rutterConnections } = useSuspenseQuery(useRutterConnectionsQueryOptions());
  const { data: rutterBalances } = useSuspenseQuery(useRutterBalancesQueryOptions());

  const balances = formatBalances(
    shopifyConnections,
    shopifyBalances,
    rutterConnections.filter(
      (store) => isRutterCommercePlatformOrPaymentProcessor(store.platformName) // Removes quickbooks
    ),
    rutterBalances
  );

  return balances;
};

const StoreAccountsSectionContent = () => {
  const balances = useStoreBalances();

  return <StoreAccountsTable data={balances} />;
};

const StoreAccountsSection = () => {
  const hideConnectedStores = useFeatureFlag(FEATURE_FLAGS["hide-connected-stores"]);

  // NB(alex): We need this hack until we have empty states for the table.
  const balances = useStoreBalances();

  if (hideConnectedStores || balances.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Store accounts</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <StoreAccountsSectionContent />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default StoreAccountsSection;
