import HighbeamBaseApi from "api/HighbeamBaseApi";
import { ChatUserRep } from "reps/chat/ChatUserRep";

export default class ChatUserApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async search(businessGuid: string, userGuid: string): Promise<ChatUserRep[]> {
    const queryParams = new URLSearchParams({ businessGuid, userGuid });
    const url = `/chat/users?${queryParams}`;
    return (await this.api.get<ChatUserRep[]>(url))!;
  }
}
