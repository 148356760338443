import { useSuspenseQuery } from "@tanstack/react-query";
import { FEATURE_FLAGS } from "flags";
import bankAccountsQueryHooks from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import useBusinessUnitCoCustomerId from "modules/business/queries/useBusinessUnitCoCustomerId";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { UNIT_CO_TRANSACTIONS_QUERY_KEY } from "modules/unit-co-transactions/queries/useUnitCoTransactionsQueryOptions";
import { startOfYear } from "utils/date";

type Params = {
  highYieldAccountGuid: string;
};

const useHighYieldInterestYtd = ({ highYieldAccountGuid }: Params) => {
  const openBankAccounts = bankAccountsQueryHooks.useData({ status: "open" });
  const unitApi = useUnitApi();
  const customerId = useBusinessUnitCoCustomerId({ required: true });
  const shouldIncludeAdjustments = useFeatureFlag(
    FEATURE_FLAGS["include-adjustment-transactions-as-interest"]
  );

  const { data } = useSuspenseQuery({
    queryKey: [UNIT_CO_TRANSACTIONS_QUERY_KEY, customerId],
    queryFn: async () => {
      const highYieldAccount = openBankAccounts.find(
        (account) =>
          account.highbeamType.name === "HighYield" && account.guid === highYieldAccountGuid
      );

      if (!highYieldAccount) {
        return [];
      }

      const response = await unitApi.transactions.list({
        sort: "-createdAt",
        customerId: customerId,
        accountId: highYieldAccount?.unitCoDepositAccountId,
        since: startOfYear().format(),
        type: shouldIncludeAdjustments ? ["Interest", "Adjustment"] : ["Interest"],
      });

      return response.data;
    },
    select: (transactions) => {
      return transactions.map((t) => t.attributes.amount).reduce((a, b) => a + b, 0);
    },
  });

  return data;
};

export default useHighYieldInterestYtd;
