import MultiStep from "layouts/MultiStep";
import bankAccountsQueryHooks from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import RepaymentBankAccountDropdown from "pages/capital/CapitalRepayPage/components/RepaymentBankAccountDropdown";
import { FC, useRef } from "react";
import { Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import BarChart from "ui/data-visualization/BarChart";
import CurrencyInput from "ui/inputs/CurrencyInputV2";
import Helper from "ui/inputs/Helper";
import RadioCardSelect from "ui/inputs/RadioCardSelect";
import RadioCardSimple from "ui/inputs/RadioCardSimple";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";

import useRepayLineOfCreditForm, {
  getRepaymentOptionMultiplier,
  RepayLineOfCreditFormInputs,
  repayLineOfCreditFormSchema,
  repaymentOptions,
} from "./useRepayLineOfCreditForm";

const REPAY_LINE_OF_CREDIT_FORM_ID = "repay-line-of-credit-form";

type RepayLineOfCreditAmountFormProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  defaultValues: RepayLineOfCreditFormInputs | null;
};

const RepayLineOfCreditAmountForm: FC<RepayLineOfCreditAmountFormProps> = ({
  capitalAccountSummary,
  defaultValues,
}) => {
  const defaultRepaymentBankAccount = bankAccountsQueryHooks.useData({
    status: "open",
    select: (openBankAccounts) => {
      return (
        openBankAccounts.find(
          (bankAccount) =>
            bankAccount.guid === capitalAccountSummary.details.repayment.bankAccountGuid
        ) ?? openBankAccounts[0]
      );
    },
  });

  const form = useRepayLineOfCreditForm({
    defaultValues: defaultValues ?? {
      amount: 0 - capitalAccountSummary.runningBalance,
      repaymentOption: null,
      repaymentBankAccount: defaultRepaymentBankAccount,
      currentAmountAvailable: capitalAccountSummary.available,
    },
  });

  const runningBalance = capitalAccountSummary.runningBalance;
  const limit = capitalAccountSummary.details.limit;

  const amountOwed = 0 - runningBalance;

  const amountInputValue = form.watch("amount");
  const selectedRepaymentBankAccount = form.watch("repaymentBankAccount");
  const selectedRepaymentOption = form.watch("repaymentOption");

  const repaymentBankAccountAvailableBalance = selectedRepaymentBankAccount.availableBalance;
  const repaymentBankAccountBalanceAfterRepayment =
    repaymentBankAccountAvailableBalance - amountInputValue;
  const insufficientFunds = repaymentBankAccountBalanceAfterRepayment < 0;

  const isOverpaying = amountInputValue > amountOwed;

  const navigate = useNavigate();

  const amountInputRef = useRef<HTMLInputElement>(null);

  const onSubmit = form.handleSubmit((_data) => {
    // NB(alex): Do not copy this pattern -- I think the correct pattern should be to save the validated data in a shared store, and to derive the inputs or keep them in a provider.
    navigate(`/capital/${capitalAccountSummary.guid}/repay/confirm`, {
      state: { repayLineOfCreditAmountFormInputs: form.getValues() },
    });
  });

  const availableAfterRepayment =
    capitalAccountSummary.available + Math.min(amountOwed, amountInputValue);
  const availableAfterRepaymentPercentage = (availableAfterRepayment / limit) * 100;

  return (
    <MultiStep.Form id={REPAY_LINE_OF_CREDIT_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            How much do you want to repay?
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <Controller
          control={form.control}
          name="repaymentOption"
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <RadioCardSelect
                  options={repaymentOptions}
                  value={field.value}
                  onChange={field.onChange}
                  rowGap={8}
                >
                  {({ option, isSelected, onSelect }) => {
                    const selectedAmount =
                      option !== "custom"
                        ? amountOwed * getRepaymentOptionMultiplier(option)
                        : form.getValues("amount");

                    return (
                      <RadioCardSimple
                        key={option}
                        value={option}
                        checked={isSelected}
                        onChange={(value) => {
                          onSelect(value);

                          if (value !== "custom") {
                            form.setValue("amount", selectedAmount);
                          } else {
                            // Focus & select the amount input when the "custom" option is selected
                            // NB(alex): I wasn't able to figure out how to unset the field so I opted to select & highlight the field instead
                            amountInputRef.current?.focus();
                            setTimeout(() => amountInputRef.current?.select(), 0);
                          }
                        }}
                        label={
                          option === "custom" ? (
                            "Custom amount"
                          ) : (
                            <MoneyAmount cents={selectedAmount} withCents />
                          )
                        }
                        helper={
                          option !== "custom"
                            ? `${getRepaymentOptionMultiplier(option) * 100}%`
                            : undefined
                        }
                      />
                    );
                  }}
                </RadioCardSelect>

                {error && <Helper iconVariant="error">{error.message}</Helper>}
              </div>
            );
          }}
        />

        <Controller
          control={form.control}
          name="amount"
          render={({ field }) => {
            return (
              <CurrencyInput
                onFocus={(e) => e.target.select()}
                inputRef={amountInputRef}
                prefixValue="$"
                label="Amount"
                className={cn(selectedRepaymentOption !== "custom" && "hidden", "mt-2")}
                {...field}
              />
            );
          }}
        />

        {insufficientFunds && (
          <Helper iconVariant="error">
            The amount you entered is greater than your account balance of{" "}
            <MoneyAmount cents={selectedRepaymentBankAccount.availableBalance} withCents />.
          </Helper>
        )}

        {isOverpaying && (
          <Helper iconVariant="error">
            The amount you entered is greater than the amount you owe.
          </Helper>
        )}
      </MultiStep.Section>

      <MultiStep.Section className="mt-8">
        <RepaymentBankAccountDropdown control={form.control} />
      </MultiStep.Section>

      <Divider className="my-8" />

      <MultiStep.Section>
        <Text color={colors.grey[900]} size={14} weight="medium">
          Capital available after repayment
        </Text>

        <BarChart height={8} className="my-3">
          <BarChart.Bar color="green" widthPercentage={availableAfterRepaymentPercentage} />
        </BarChart>

        <MoneyAmountFraction
          numeratorInCents={availableAfterRepayment}
          denominatorInCents={limit}
        />
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.NextButton
          form={REPAY_LINE_OF_CREDIT_FORM_ID}
          disabled={!amountInputValue || insufficientFunds || isOverpaying}
        >
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const RepayLineOfCreditAmountView: FC<Props> = ({ capitalAccountSummary }) => {
  const { state } = useLocation();

  const repayLineOfCreditAmountFormInputs: RepayLineOfCreditFormInputs | null =
    repayLineOfCreditFormSchema.safeParse(state?.repayLineOfCreditAmountFormInputs).success
      ? state?.repayLineOfCreditAmountFormInputs
      : null;

  return (
    <RepayLineOfCreditAmountForm
      capitalAccountSummary={capitalAccountSummary}
      defaultValues={repayLineOfCreditAmountFormInputs}
    />
  );
};

export default RepayLineOfCreditAmountView;
