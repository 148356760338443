import { captureException } from "@sentry/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Milestone } from "modules/action-items/types";
import useBankApplicationQueryOptions from "modules/bank-application/queries/useBankApplicationQueryOptions";
import { useIsCurrentBusinessMemberOnboarded } from "modules/business-members/queries/businessMemberQueryHooks";
import useBusiness from "modules/business/queries/useBusiness";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import useMfa from "modules/mfa/useMfa";
import { useResetRecoilState } from "recoil";
import BankApplicationRep from "reps/BankApplicationRep";
import actionItemsState from "state/actionItems/actionItems";
import { useRefreshJwt } from "state/auth/jwt";
import { SetRequired } from "type-fest";
import { notify } from "ui/feedback/Toast";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useAuthorizedUserApi from "../api/useAuthorizedUserApi";

type AdditionalOptions = SetRequired<MutationAdditionalOptions<void, void>, "onSuccess">;

const useGetStartedMutation = (additionalOptions: AdditionalOptions) => {
  const authorizedUserApi = useAuthorizedUserApi();
  const businessGuid = useBusinessGuid();
  const userGuid = useUserGuid();
  const isOnboarded = useIsCurrentBusinessMemberOnboarded();
  const refreshJwt = useRefreshJwt();
  const { segmentTrack } = useSegment();
  const { mfa } = useMfa();
  const resetActionItemsState = useResetRecoilState(actionItemsState(Milestone.Signup));

  // Redirects pending approval.
  const { unitCoCustomerId } = useBusiness();
  const { data: bankApplication } = useSuspenseQuery(useBankApplicationQueryOptions());
  const isApplicationApproved = bankApplication?.status === BankApplicationRep.Status.Approved;

  return useMutationWithDefaults(
    {
      mutationFn: async () => {
        if (!unitCoCustomerId) {
          if (isApplicationApproved) {
            // Prevents an infinite loop caused by a rare edge case where the application is
            // approved but `unitCoCustomerId` is undefined. Context:
            // https://highbeamco.slack.com/archives/C05A92512J0/p1697139667912349
            throw new Error("Bank application is approved but `unitCoCustomerId` is undefined.");
          }
          // NB(alex): We previously redirected the user, but this resulted in an infinite
          // navigation loop. It is up to the caller of this mutation to ensure this condition never
          // occurs. https://highbeamco.slack.com/archives/C07HGFN06PR/p1737556755313969
          throw new Error("Bank application not approved.");
        }

        if (isOnboarded) {
          return;
        }

        // This fixes an issue where newly invited users are prompted to MFA even though they just MFA'd during the sign up process.
        try {
          await refreshJwt();
        } catch (e) {
          captureException(e);
        }

        // We require the user to MFA first in order to ensure they have a phone number configured with Auth0.
        // This phone number will be passed through to Unit.
        await mfa();
        segmentTrack(SEGMENT_EVENTS.ONBOARDING_COMPLETE);

        return authorizedUserApi.create(businessGuid, userGuid);
      },
      onError: () => {
        notify("error", "Something went wrong! Please try again or contact support.");
      },
      onSuccess: () => {
        // The backend creates an action item "Signup/AuthorizeWithUnit" upon completion, which we need to be aware of.
        resetActionItemsState();
      },
    },
    additionalOptions
  );
};

export default useGetStartedMutation;
