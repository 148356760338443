import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import cardAuthorizationQueryHooks from "modules/card-authorizations/queries/cardAuthorizationQueryHooks";
import { FC } from "react";
import Flexpane from "ui/overlay/Flexpane";

import CardAuthorizationDetails from "./CardAuthorizationDetails";
import CardAuthorizationFlexpaneDispute from "./CardAuthorizationFlexpaneDispute";
import CardAuthorizationFlexpaneParties from "./CardAuthorizationFlexpaneParties";

type CardAuthorizationFlexpaneContentProps = {
  authorizationId: string;
  onClose: () => void;
};

const CardAuthorizationFlexpaneContent: FC<CardAuthorizationFlexpaneContentProps> = ({
  authorizationId,
  onClose,
}) => {
  const { data: authorization } = cardAuthorizationQueryHooks.useQuery({
    authorizationId: authorizationId,
    throwOnError: true,
    select: (data) => data.data,
  });

  return (
    <>
      <Flexpane.Header onClose={onClose}>Transaction info</Flexpane.Header>

      <Flexpane.Section>
        {authorization ? (
          <CardAuthorizationFlexpaneParties authorization={authorization} />
        ) : (
          <Flexpane.SectionShimmer />
        )}
      </Flexpane.Section>

      <Flexpane.Section>
        {authorization ? (
          <TransactionFlexpaneAmount cents={authorization.attributes.amount} direction="negative" />
        ) : (
          <Flexpane.SectionShimmer />
        )}
      </Flexpane.Section>

      <Flexpane.Section>
        {authorization ? (
          <CardAuthorizationDetails authorization={authorization} />
        ) : (
          <Flexpane.SectionShimmer />
        )}
      </Flexpane.Section>

      <Flexpane.Section>
        {authorization ? (
          <CardAuthorizationFlexpaneDispute authorization={authorization} onClose={onClose} />
        ) : (
          <Flexpane.SectionShimmer />
        )}
      </Flexpane.Section>
    </>
  );
};

type Props = {
  authorizationId?: string;
  onClose: () => void;
};

const CardAuthorizationFlexpane: FC<Props> = ({ authorizationId, onClose }) => {
  return (
    <Flexpane isOpen={Boolean(authorizationId)} onClose={onClose}>
      {authorizationId && (
        <CardAuthorizationFlexpaneContent authorizationId={authorizationId} onClose={onClose} />
      )}
    </Flexpane>
  );
};

export default CardAuthorizationFlexpane;
