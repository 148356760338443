import { FC } from "react";
import { ContentsMessageLogRep } from "reps/chat/MessageLogRep";

import { MessageLogRepDisplayProps } from "./MessageLogRepDisplay";

const ContentsMessageLogRepDisplay: FC<MessageLogRepDisplayProps<ContentsMessageLogRep>> = () => {
  return null; // This shows nothing for now. In the future it will show something.
};

export default ContentsMessageLogRepDisplay;
