import { FC } from "react";
import { PromptBuildingTimeMessageLogRep } from "reps/chat/MessageLogRep";
import { Paragraph } from "ui/typography";

import MessageLogContainer from "./MessageLogContainer";
import MessageLogIndicator from "./MessageLogIndicator";
import { MessageLogRepDisplayProps } from "./MessageLogRepDisplay";

const PromptBuildingTimeMessageLogRepDisplay: FC<
  MessageLogRepDisplayProps<PromptBuildingTimeMessageLogRep>
> = ({ messageLog }) => {
  return (
    <MessageLogContainer className="flex flex-row items-center gap-2 bg-purple-100">
      <MessageLogIndicator variant={indicatorVariant(messageLog.milliseconds)} />
      <Paragraph>
        Built <span className="select-all font-medium">{messageLog.name}</span> prompt in{" "}
        <span className="font-monospace">
          <span className="select-all">{messageLog.milliseconds}</span>
          {"\u200B"}ms
        </span>
      </Paragraph>
    </MessageLogContainer>
  );
};

export default PromptBuildingTimeMessageLogRepDisplay;

const indicatorVariant = (milliseconds: number) => {
  if (milliseconds < 50) return "happy";
  if (milliseconds < 500) return "meh";
  return "sad";
};
