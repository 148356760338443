import { chatChannelsByBusiness } from "modules/chat/queries/useChatChannelsByBusiness";
import { useChatUsers, useCurrentChatUser } from "modules/chat/queries/useChatUsers";
import { FC, useMemo } from "react";
import { ChatUserRep } from "reps/chat/ChatUserRep";

import SuperuserChannelChoice from "./SuperuserChannelChoice";

const SuperuserChannelsPicker: FC = () => {
  const channels = chatChannelsByBusiness.useData({
    params: {},
  });

  const humanChatUsers = useChatUsers()
    .filter((chatUser) => chatUser.type === "BusinessMember" || chatUser.type === "Highbeam")
    .reduce((acc: Record<string, ChatUserRep>, chatUser) => {
      acc[chatUser.id] = chatUser;
      return acc;
    }, {});

  const currentChatUserId = useCurrentChatUser()?.id;

  const superuserChannels = useMemo(() => {
    return channels
      .map((channel) => {
        const chatUser = channel.chatUserIds
          .map((chatUserId) => humanChatUsers[chatUserId])
          .find((chatUser) => chatUser);
        return {
          id: channel.id,
          name: (() => {
            if (!chatUser) return "Unknown";
            if (chatUser.id === currentChatUserId) return "[You] " + chatUser.displayName;
            if (chatUser.type === "Highbeam") return "[Highbeam] " + chatUser.displayName;
            return chatUser.displayName;
          })(),
        };
      })
      .toSorted((a, b) => {
        // Show [You] first.
        if (a.name.startsWith("[You] ") && !b.name.startsWith("[You] ")) return -1;
        if (b.name.startsWith("[You] ") && !a.name.startsWith("[You] ")) return 1;
        // Show [Highbeam] last.
        if (a.name.startsWith("[Highbeam] ") && !b.name.startsWith("[Highbeam] ")) return 1;
        if (b.name.startsWith("[Highbeam] ") && !a.name.startsWith("[Highbeam] ")) return -1;
        // Otherwise, alphabetize.
        return a.name.localeCompare(b.name);
      });
  }, [channels, currentChatUserId, humanChatUsers]);

  return (
    <div className="w-7xl my-8 flex flex-col gap-4 self-center">
      {superuserChannels.map((channel) => {
        return <SuperuserChannelChoice key={channel.id} channel={channel} />;
      })}
    </div>
  );
};

export default SuperuserChannelsPicker;
