import { ArrowRight } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import { AiChatAnalystAvatar } from "modules/chat/components/AiChatUserAvatar";
import useCreateChatChannelMessageMutation from "modules/chat/mutations/useCreateChatChannelMessageMutation";
import { useMvpFirstChatChannel } from "modules/chat/queries/useChatChannels";
import { useCurrentChatUserOrThrow } from "modules/chat/queries/useChatUsers";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SuggestedQuestionRep } from "reps/chat/SuggestedQuestionRep";
import ChatSuggestionBubble from "ui/chat/ChatSuggestionBubble";
import FeatureStatusBadge from "ui/data-display/FeatureStatusBadge";
import VirtualButton from "ui/inputs/VirtualButton";
import { Heading4 } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  suggestedQuestions: SuggestedQuestionRep[];
};

const AiAnalystNudgeSection: FC<Props> = ({ suggestedQuestions }) => {
  const navigate = useNavigate();
  const currentChatUser = useCurrentChatUserOrThrow();
  const mvpChatChannel = useMvpFirstChatChannel({ chatUserId: currentChatUser.id });

  const [selectedButtonQuestionId, setSelectedButtonQuestionId] = useState<string | null>(null);

  const { mutateAsync: createChatChannelMessage, isPending } = useCreateChatChannelMessageMutation({
    onSuccess: (data) => {
      navigate(`/ai/${data.channelId}`);
    },
    onSettled: () => {
      setSelectedButtonQuestionId(null);
    },
  });

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header className="gap-x-4">
        <AiChatAnalystAvatar />
        <Heading4 className="flex items-center gap-x-2.5 bg-[linear-gradient(90deg,#178428_0%,#15A22C_100%)] bg-clip-text text-transparent">
          Ask AI Analyst
          <FeatureStatusBadge featureStatus="Beta" />
        </Heading4>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body className="flex flex-wrap gap-3">
        {suggestedQuestions.map((question) => (
          <VirtualButton
            key={question.id}
            onClick={() => {
              createChatChannelMessage({
                channelId: mvpChatChannel.id,
                content: question.plaintext,
                parentId: null,
              });
              setSelectedButtonQuestionId(question.id);
            }}
            disabled={isPending}
            className={cn(
              selectedButtonQuestionId === question.id && "data-[disabled=true]:opacity-50"
            )}
          >
            <ChatSuggestionBubble>{question.markdown}</ChatSuggestionBubble>
          </VirtualButton>
        ))}
        <Link to={`/ai/${mvpChatChannel.id}`}>
          <ChatSuggestionBubble>
            See more <ArrowRight />
          </ChatSuggestionBubble>
        </Link>
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default AiAnalystNudgeSection;
