import {
  ArrowLineUpRight,
  ArrowsLeftRight,
  Cards,
  ChartLine,
  ChatTeardropDots,
  Coins,
  FileSearch,
  Gear,
  Money,
  X,
} from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import logo from "assets/highbeam-logo.svg";
import classNames from "classnames";
import { useChatWidget } from "components/ChatWidget";
import { FEATURE_FLAGS } from "flags";
import capitalAccountsQueryHooks from "modules/capital-accounts/queries/capitalAccountsQueryHooks";
import useChatIsSuperuserOnly from "modules/chat/hook/useChatIsSuperuserOnly";
import useIsAllowedToNavigateToChatAdminRoutes from "modules/chat/hook/useIsAllowedToNavigateToChatAdminRoutes";
import useIsAllowedToNavigateToChatRoutes from "modules/chat/hook/useIsAllowedToNavigateToChatRoutes";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import usePaymentApprovalsQueryOptions from "modules/payment-approvals/queries/usePaymentApprovalsQueryOptions";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import isSidebarOpenState from "state/sidebar";
import CountBadge from "ui/data-display/CountBadge";
import Spacer from "ui/data-display/Spacer";
import ChequeIcon from "ui/icons/ChequeIcon";
import PaymentIcon from "ui/icons/PaymentIcon";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useIsAllowedToNavigateToAccountsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToAccountsRoutes";
import useIsAllowedToNavigateToCapitalRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToCapitalRoutes";
import useIsAllowedToNavigateToCardTransactionsPage from "utils/permissions/navigation/useIsAllowedToNavigateToCardTransactionsPage";
import useIsAllowedToNavigateToDepositCheckPage from "utils/permissions/navigation/useIsAllowedToNavigateToDepositCheckPage";
import useIsAllowedToNavigateToMoveMoneyRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToMoveMoneyRoutes";
import useIsAllowedToNavigateToPaymentsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToPaymentsRoutes";
import useIsAllowedToNavigateToSpendRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToSpendRoutes";
import useIsAllowedToNavigateToTransferMoney from "utils/permissions/navigation/useIsAllowedToNavigateToTransferMoney";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import useIsAllowedToCreateDraftPayments from "utils/permissions/useIsAllowedToCreateDraftPayments";
import useIsAllowedToViewSendMoney from "utils/permissions/useIsAllowedToViewSendMoney";

import BillPayMenuRenderer from "./BillPayMenuRenderer";
import styles from "./DashboardSidebar.module.scss";
import GetStartedSetupGuide from "./GetStartedSetupGuide";
import useCloseSidebarOnNavigateEffect from "./hooks/useCloseSidebarOnNavigateEffect";
import MenuItem from "./MenuItem";
import MenuSubItem from "./MenuSubItem";
import NavFeatureStatusBadge from "./NavFeatureStatusBadge";
import SidebarMenu from "./SidebarMenu";
import { getCountBadgeColor } from "./utils";

const DashboardSidebar = () => {
  const [show, setIsSidebarOpen] = useRecoilState(isSidebarOpenState);
  const onClose = () => setIsSidebarOpen(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const chat = useChatWidget();

  const isAllowedToNavigateToMoveMoneyRoutes = useIsAllowedToNavigateToMoveMoneyRoutes();
  const isAllowedToNavigateToTransferMoney = useIsAllowedToNavigateToTransferMoney();
  const isAllowedToNavigateToDepositCheckPage = useIsAllowedToNavigateToDepositCheckPage();
  const isAllowedToNavigateToAccountsRoutes = useIsAllowedToNavigateToAccountsRoutes();
  const isAllowedToNavigateToPaymentsRoutes = useIsAllowedToNavigateToPaymentsRoutes();

  const isAllowedToNavigateToCapitalRoutes = useIsAllowedToNavigateToCapitalRoutes();
  const isAllowedToNavigateToSpendRoutes = useIsAllowedToNavigateToSpendRoutes();
  const isAllowedToNavigateToCardTransactionsPage = useIsAllowedToNavigateToCardTransactionsPage();
  const isAllowedToNavigateToChatRoutes = useIsAllowedToNavigateToChatRoutes();
  const isAllowedToNavigateToChatAdminRoutes = useIsAllowedToNavigateToChatAdminRoutes();

  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isAllowedToCreatePaymentDrafts = useIsAllowedToCreateDraftPayments();
  const isAllowedToViewSendMoney = useIsAllowedToViewSendMoney();

  const insightsCashFlowViewEnabled = useFeatureFlag(FEATURE_FLAGS["cash-flow-view"]);
  const chatIsSuperuserOnly = useChatIsSuperuserOnly();

  const { data: paymentApprovals } = useQuery({
    ...usePaymentApprovalsQueryOptions("Open"),
    enabled: isAllowedToApprovePayments || isAllowedToCreatePaymentDrafts,
  });
  const paymentApprovalsCount = paymentApprovals ? paymentApprovals.length : 0;

  const { data: hasCapitalAccount } = capitalAccountsQueryHooks.useQuery({
    select: (capitalAccounts) => capitalAccounts.length > 0,
    enabled: isAllowedToNavigateToCapitalRoutes,
  });

  useCloseSidebarOnNavigateEffect();

  return (
    <div className={classNames({ [styles.container]: true, [styles.closed]: !show })}>
      <div>
        <div className={styles.header}>
          <div className={styles.subHeader}>
            <Link to="/" className={styles.homeLink} onClick={onClose}>
              <img src={logo} className={styles.logo} alt="logo" />
            </Link>
            <X onClick={onClose} size={24} className={styles.close} />
          </div>
        </div>

        <div className={styles.actionButton}>
          {isAllowedToNavigateToMoveMoneyRoutes && (
            <SidebarMenu
              customButton={
                <Button className="w-full rounded-3xl border border-purple-100" variant="secondary">
                  <ArrowsLeftRight />
                  Move money
                </Button>
              }
            >
              {isAllowedToApprovePayments && (
                <SidebarMenu.Item
                  label="Send money"
                  icon={<ArrowLineUpRight size={20} />}
                  subText="to pay bills, etc."
                  onClick={() => {
                    // Hack to reload send money page if already on send money page. https://linear.app/highbeam/issue/HB-4999/nothing-happens-on-clicking-send-money-right-after-making-a-payment
                    if (pathname === "/send-money") {
                      window.location.reload();
                    } else {
                      navigate("/send-money");
                    }
                  }}
                />
              )}
              {!isAllowedToApprovePayments && isAllowedToCreatePaymentDrafts && (
                <SidebarMenu.Item
                  label="Draft a payment"
                  icon={<ArrowLineUpRight size={20} />}
                  subText="for admin approval"
                  onClick={() => {
                    navigate("/send-money");
                  }}
                />
              )}
              {isAllowedToNavigateToTransferMoney && (
                <SidebarMenu.Item
                  label="Transfer money"
                  icon={<ArrowsLeftRight size={20} />}
                  subText="between bank accounts"
                  onClick={() => {
                    navigate("/transfer-money");
                  }}
                />
              )}
              {isAllowedToNavigateToDepositCheckPage && (
                <SidebarMenu.Item
                  label="Deposit check"
                  icon={<ChequeIcon size={20} />}
                  subText="to your Highbeam account"
                  onClick={() => {
                    navigate("/deposit-check");
                  }}
                />
              )}
            </SidebarMenu>
          )}
        </div>

        {isAllowedToNavigateToAccountsRoutes && (
          <MenuItem text="Accounts" icon={<Money />} path="/accounts" onClick={onClose}>
            <MenuSubItem text="Overview" path="/accounts" onClose={onClose} />
            <MenuSubItem text="Transactions" path="/accounts/transactions" onClose={onClose} />
            <MenuSubItem text="Documents" path="/accounts/documents" onClose={onClose} />
          </MenuItem>
        )}

        <MenuItem text="Cards" icon={<Cards />} path="/cards" onClick={onClose}>
          <MenuSubItem text="Overview" path="/cards" onClose={onClose} />
          {isAllowedToNavigateToCardTransactionsPage && (
            <MenuSubItem text="Transactions" path="/cards/transactions" onClose={onClose} />
          )}
        </MenuItem>

        {isAllowedToNavigateToPaymentsRoutes && (
          <MenuItem
            text="Payments"
            icon={<PaymentIcon />}
            path="/payments"
            onClick={onClose}
            badge={({ isActive }) => {
              if (isAllowedToViewSendMoney && paymentApprovalsCount) {
                return (
                  <CountBadge
                    count={paymentApprovalsCount}
                    backgroundColor={getCountBadgeColor(isActive)}
                  />
                );
              }
            }}
          >
            <MenuSubItem
              key="overview"
              text="Overview"
              path="/payments"
              onClose={onClose}
              badge={({ isActive }) => {
                if (isAllowedToViewSendMoney && paymentApprovalsCount) {
                  return (
                    <CountBadge
                      count={paymentApprovalsCount}
                      backgroundColor={getCountBadgeColor(isActive)}
                    />
                  );
                }
              }}
            />
            <MenuSubItem key="payees" text="Payees" path="/payments/payees" onClose={onClose} />
          </MenuItem>
        )}

        <ErrorBoundary fallback={null}>
          <BillPayMenuRenderer onClose={onClose} />
        </ErrorBoundary>

        {isAllowedToNavigateToChatRoutes && (
          <MenuItem
            text={(chatIsSuperuserOnly ? "[SU] " : "") + "AI Analyst"}
            icon={<FileSearch />}
            path="/ai"
            onClick={onClose}
            badge={({ isActive }) => (
              <NavFeatureStatusBadge featureStatus="Beta" isActive={isActive} />
            )}
          >
            {isAllowedToNavigateToChatAdminRoutes && (
              <>
                <MenuSubItem text="[SU] Admin" path="/ai/admin" onClose={onClose} />
                <MenuSubItem
                  text="[SU] Knowledge Base"
                  path="/ai/knowledge-base"
                  onClose={onClose}
                />
              </>
            )}
          </MenuItem>
        )}

        {isAllowedToNavigateToCapitalRoutes && (
          <MenuItem text="Capital" icon={<Coins />} path="/capital" onClick={onClose}>
            <MenuSubItem text={"Overview"} path="/capital" onClose={onClose} />

            {hasCapitalAccount && (
              <MenuSubItem text="Documents" path="/capital/documents" onClose={onClose} />
            )}
            <MenuSubItem
              text="Compare other offers"
              path={CREDIT_COMPARISON_PATH}
              onClose={onClose}
            />
          </MenuItem>
        )}

        {isAllowedToNavigateToSpendRoutes && insightsCashFlowViewEnabled && (
          <MenuItem text="Cash flow" icon={<ChartLine />} path="/cash-flow" onClick={onClose} />
        )}

        <MenuItem text="Settings" icon={<Gear />} path="/settings" onClick={onClose} />

        <Spacer height={44} />
      </div>

      <Spacer height={16} />

      <div className={styles.footer}>
        <Spacer height={16} />
        <Suspense fallback={null}>
          <div className="mb-4 hidden tablet-landscape:block">
            <GetStartedSetupGuide />
          </div>
        </Suspense>
        <div className={styles.contactusWrapper} onClick={() => chat.show()}>
          <ChatTeardropDots size={14} />
          <Text size={12} weight="bold">
            Contact support
          </Text>
        </div>
        <Spacer height={16} />
        <div className={styles.copyrightContainer}>
          <Text size={12} className={styles.copyrightText}>
            &copy; {new Date().getFullYear()} Highbeam Inc.
          </Text>
        </div>
        <Spacer height={16} />
      </div>
    </div>
  );
};

export default DashboardSidebar;
