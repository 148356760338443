import { FC, ReactNode } from "react";
import cn from "utils/tailwind/cn";

type Props = {
  children: ReactNode;
  className?: string;
};

const MessageLogContainer: FC<Props> = ({ children, className }) => {
  return <div className={cn("mx-6 my-2 rounded-md p-4", className)}>{children}</div>;
};

export default MessageLogContainer;
