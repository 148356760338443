import { Dayjs } from "dayjs";
import { DEMO_BUSINESS_GUID } from "modules/demo-account/constants";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { startOfBankingDay } from "utils/date";
import useQueryOptions from "utils/react-query/useQueryOptions";
import { DEFAULT_RELATIVE_TIMEFRAME, timeframeToNumDays, timeframeToSince } from "utils/timeframe";

import generateFakeData from "../utils/generateFakeData";
import getHistoryWithTodayForSingleAccount from "../utils/getHistoryWithTodayForSingleAccount";

import bankAccountsQueryHooks from "./bankAccountsQueryHooks";

// NB(lev): We subtract extra days from the since date that we technically
// need to ensure we get all the data. Unit has an apparent bug where it doesn't
// return entries for the first few days right after the since param.
// Note that later we cut the history entries to the number of days we actually
//want to display.
const makeOverflowSinceDate = (since: Dayjs): Dayjs => since.subtract(7, "days");

const useBankAccountBalanceHistoryQueryOptions = (
  accountId: string,
  timeframe = DEFAULT_RELATIVE_TIMEFRAME
) => {
  const unitApi = useUnitApi();
  const bankAccounts = bankAccountsQueryHooks.useData({ status: "all" });
  const currentBankAccount = bankAccounts.find(
    (bankAccount) => bankAccount.unitCoDepositAccountId === accountId
  );
  const currentBankAccountBalance = currentBankAccount?.availableBalance;
  const businessGuid = currentBankAccount?.businessGuid;

  return useQueryOptions({
    queryKey: ["bankAccountBalanceHistory", { accountId, businessGuid, timeframe }],
    queryFn: async () => {
      const numDays = timeframeToNumDays(timeframe);
      const since = timeframeToSince(timeframe);

      const accountsEndOfDay = await unitApi.accountsEndOfDay.list({
        since: makeOverflowSinceDate(since).format("YYYY-MM-DD"),
        accountId: accountId,
        // Get maximum number of entries. #yolo
        limit: 1000,
      });

      // Trim history entries to just the days we want to display.
      const history = accountsEndOfDay
        ? accountsEndOfDay.data
            .filter((datum) => {
              const referenceDateInstance = startOfBankingDay(datum.attributes.date);
              return referenceDateInstance.isSame(since) || referenceDateInstance.isAfter(since);
            })
            .slice(0, numDays)
        : [];
      const historyWithToday =
        history.length > 0 || currentBankAccountBalance
          ? getHistoryWithTodayForSingleAccount(history, currentBankAccountBalance || 0, timeframe)
          : [];

      if (businessGuid === DEMO_BUSINESS_GUID) {
        return generateFakeData(historyWithToday);
      }

      return historyWithToday;
    },
  });
};

export default useBankAccountBalanceHistoryQueryOptions;
