import { useCallback, useEffect, useMemo, useState } from "react";
import { MessageRep } from "reps/chat/MessageRep";

import getIsAtBottom from "./getIsAtBottom";

// NB(alex): This took me a while to figure out. We only want to count the new messages **from
// other users** that have yet to be read. Instead of tallying the number of unread messages, it
// has turned out to be much easier to just keep track of the most recently read message, and to
// find that message's index, and to then get the number of messages that have come in since then.

type Params = {
  messages: MessageRep[];
  currentChatUserId: string;
  scrollContainerElement: HTMLDivElement | null;
};

const useUnreadMessagesCount = ({
  messages,
  currentChatUserId,
  scrollContainerElement,
}: Params) => {
  const otherUserContentIds = useMemo(
    () =>
      messages.flatMap((message) =>
        message.contents
          .filter((content) => content.senderId !== currentChatUserId)
          .map((_content, contentIndex) => `${message.id}-${contentIndex}`)
      ),
    [messages, currentChatUserId]
  );

  const [lastReadMessageContentId, setLastReadMessageContentId] = useState<string>(
    otherUserContentIds[0]
  );

  const lastReadMessageContentIndex = otherUserContentIds.indexOf(lastReadMessageContentId);

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const resetUnreadMessagesCount = useCallback(() => {
    setLastReadMessageContentId(otherUserContentIds[0]);
    setUnreadMessagesCount(0);
  }, [otherUserContentIds]);

  // Only set the messages count if not at bottom, otherwise reset state.
  useEffect(() => {
    if (scrollContainerElement) {
      const isAtBottom = getIsAtBottom(scrollContainerElement);
      if (!isAtBottom && lastReadMessageContentId !== undefined) {
        setUnreadMessagesCount(lastReadMessageContentIndex);
      } else {
        resetUnreadMessagesCount();
      }
    }
  }, [
    unreadMessagesCount,
    resetUnreadMessagesCount,
    lastReadMessageContentIndex,
    lastReadMessageContentId,
    scrollContainerElement,
  ]);

  return {
    unreadMessagesCount: unreadMessagesCount,
    resetUnreadMessagesCount,
  };
};

export default useUnreadMessagesCount;
