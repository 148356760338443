import { FC } from "react";
import { MessageLogRep } from "reps/chat/MessageLogRep";

import ContentsMessageLogRepDisplay from "./ContentsMessageLogRepDisplay";
import EndToEndTimeMessageLogRepDisplay from "./EndToEndTimeMessageLogRepDisplay";
import ExceptionMessageLogRepDisplay from "./ExceptionMessageLogRepDisplay";
import LlmRequestMessageLogRepDisplay from "./LlmRequestMessageLogRepDisplay";
import PromptBuildingTimeMessageLogRepDisplay from "./PromptBuildingTimeMessageLogRepDisplay";

export type MessageLogRepDisplayProps<T extends MessageLogRep> = {
  messageLog: T;
};

const MessageLogRepDisplay: FC<MessageLogRepDisplayProps<MessageLogRep>> = ({ messageLog }) => {
  switch (messageLog.type) {
    case "Contents":
      return <ContentsMessageLogRepDisplay messageLog={messageLog} />;
    case "EndToEndTime":
      return <EndToEndTimeMessageLogRepDisplay messageLog={messageLog} />;
    case "Exception":
      return <ExceptionMessageLogRepDisplay messageLog={messageLog} />;
    case "LlmRequest":
      return <LlmRequestMessageLogRepDisplay messageLog={messageLog} />;
    case "PromptBuildingTime":
      return <PromptBuildingTimeMessageLogRepDisplay messageLog={messageLog} />;
  }
};

export default MessageLogRepDisplay;
