import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { Dayjs } from "dayjs";
import bankAccountsQueryHooks, {
  ROOT_BANK_ACCOUNTS_QUERY_KEY,
} from "modules/bank-accounts/queries/bankAccountsQueryHooks";
import generateFakeData from "modules/bank-accounts/utils/generateFakeData";
import getHistoryWithTodayForMultipleAccounts from "modules/bank-accounts/utils/getHistoryWithTodayForMultipleAccounts";
import getTotalBalanceOfBankAccounts from "modules/bank-accounts/utils/getTotalBalanceOfBankAccounts";
import { DEMO_BUSINESS_GUID } from "modules/demo-account/constants";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import { startOfBankingDay } from "utils/date";
import { DEFAULT_RELATIVE_TIMEFRAME, timeframeToNumDays, timeframeToSince } from "utils/timeframe";

// NB(lev): We subtract extra days from the since date that we technically
// need to ensure we get all the data. Unit has an apparent bug where it doesn't
// return entries for the first few days right after the since param.
// Note that later we cut the history entries to the number of days we actually
//want to display.
const makeOverflowSinceDate = (since: Dayjs): Dayjs => since.subtract(7, "days");

const useBankAccountBalanceHistoryQuery = (timeframe = DEFAULT_RELATIVE_TIMEFRAME) => {
  const unitApi = useUnitApi();

  const openBankAccounts = bankAccountsQueryHooks.useData({ status: "open" });

  const bankAccountsToIncludeInTotalBalance = openBankAccounts.filter(
    (account) => account.highbeamType.includeInTotalBalance
  );

  // Allows us to seed balance graph for the demo account.
  const businessGuid = useBusinessGuid();

  return useQuery({
    queryKey: [ROOT_BANK_ACCOUNTS_QUERY_KEY, { businessGuid, timeframe }],
    queryFn: async () => {
      const numDays = timeframeToNumDays(timeframe);
      const since = timeframeToSince(timeframe);

      const responses = await Promise.all(
        bankAccountsToIncludeInTotalBalance.map((bankAccount) =>
          unitApi.accountsEndOfDay.list({
            since: makeOverflowSinceDate(since).format("YYYY-MM-DD"),
            accountId: bankAccount.unitCoDepositAccountId,
            // Get maximum number of entries. #yolo
            limit: 1000,
          })
        )
      );

      // Trim history entries to just the days we want to display.
      const histories = responses.map((unitAccountEndOfDay) => {
        return unitAccountEndOfDay.data
          .filter((datum) => {
            const referenceDateInstance = startOfBankingDay(datum.attributes.date);
            return referenceDateInstance.isSame(since) || referenceDateInstance.isAfter(since);
          })
          .slice(0, numDays);
      });

      const bankAccountsTotalBalance = getTotalBalanceOfBankAccounts(openBankAccounts);
      const historyWithToday = getHistoryWithTodayForMultipleAccounts(
        histories,
        bankAccountsTotalBalance,
        timeframe
      );

      if (businessGuid === DEMO_BUSINESS_GUID) {
        return generateFakeData(historyWithToday);
      }

      return historyWithToday;
    },
    placeholderData: keepPreviousData,
  });
};

export default useBankAccountBalanceHistoryQuery;
