import CapitalAccountRep from "reps/CapitalAccountRep";
import { Merge } from "type-fest";

import capitalAccountsQueryHooks from "../queries/capitalAccountsQueryHooks";

// NB(alex): This is a hack - we shouldn't actually be using capital accounts to store terminated offers, but this is how things are.
export type TerminatedOfferCapitalAccount = Merge<
  CapitalAccountRep.Complete,
  {
    state: CapitalAccountRep.State.Terminated;
    activatedAt: null;
  }
>;

export type TerminatedCapitalAccount = Merge<
  CapitalAccountRep.Complete,
  {
    state: CapitalAccountRep.State.Terminated;
    activatedAt: string;
  }
>;

export const checkIsTerminatedCapitalAccount = (
  capitalAccount: CapitalAccountRep.Complete
): capitalAccount is TerminatedCapitalAccount => {
  return (
    capitalAccount.state === CapitalAccountRep.State.Terminated &&
    capitalAccount.activatedAt !== null
  );
};

export const useTerminatedCapitalAccountsQuery = () => {
  return capitalAccountsQueryHooks.useQuery({
    select: (capitalAccounts) => capitalAccounts.filter(checkIsTerminatedCapitalAccount),
  });
};

const useTerminatedCapitalAccounts = () => {
  return capitalAccountsQueryHooks.useData({
    select: (capitalAccounts) => capitalAccounts.filter(checkIsTerminatedCapitalAccount),
  });
};

export default useTerminatedCapitalAccounts;
