import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

type Params = {
  channelId: string;
  latestExclusive: string | null;
};

const chatChannelMessagesQueryHooks = makeQueryHooks({
  name: "chatChannelMessages",
  useQueryVariables: (params: Params) => {
    return params;
  },
  useQueryFnMaker: ({ channelId, latestExclusive }) => {
    const highbeamApi = useHighbeamApi();
    return () => highbeamApi.message.searchChannel(channelId, latestExclusive);
  },
});

export const useChatChannelMessages = (params: Params) => {
  return chatChannelMessagesQueryHooks.useData({ ...params, refetchInterval: 1000 });
};

export const useRefreshChatChannelMessages = () => {
  return chatChannelMessagesQueryHooks.useRefreshQueries();
};
