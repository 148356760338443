import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import { HighbeamApiError } from "utils/ajax";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

const plaidConnectionsQueryHooks = makeQueryHooks({
  name: "plaidConnections",
  useQueryVariables: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  useQueryFnMaker: ({ businessGuid }) => {
    const highbeamApi = useHighbeamApi();
    return async () => {
      try {
        return await highbeamApi.plaid.getPlaidConnectionsByBusiness(businessGuid);
      } catch (error) {
        if (error instanceof HighbeamApiError) {
          // Handles user role error. https://highbeamco.slack.com/archives/C05A92512J0/p1738075202885299
          // NB(alex): Could be nice if the backend gave us an error type, because the 403 might not be caused by an insufficient user role.
          if (error.statusCode === 403) {
            return [];
          }
        }
        throw error;
      }
    };
  },
});

export default plaidConnectionsQueryHooks;

// Hooks

export const usePlaidConnections = () => {
  return plaidConnectionsQueryHooks.useData({});
};

export const useHasPlaidConnections = () => {
  return usePlaidConnections().length > 0;
};

export const useRefreshPlaidConnectionsQueries = () => {
  return plaidConnectionsQueryHooks.useRefreshQueries({});
};
