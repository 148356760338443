import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { ROOT_BUSINESSES_QUERY_KEY } from "modules/businesses/queries/businessesQueryHooks";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import BusinessRep from "reps/BusinessRep";
import { HighbeamApiError } from "utils/ajax";
import { DataRequired, LoginRequiredParam } from "utils/react-query/require-data";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useBusinessApi from "../api/useBusinessApi";

export const useBusinessQueryOptions = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  const businessGuid = useBusinessGuid(params);
  const businessApi = useBusinessApi();

  return useQueryOptions({
    queryKey: [ROOT_BUSINESSES_QUERY_KEY, "business", { businessGuid }],
    queryFn: async (): Promise<DataRequired<BusinessRep.Complete, TRequired>> => {
      if (!businessGuid) {
        if (!params.loginRequired) {
          return null as DataRequired<BusinessRep.Complete, TRequired>;
        }
        throw new Error("businessGuid is missing");
      }
      try {
        return (await businessApi.get(businessGuid)) as DataRequired<
          BusinessRep.Complete,
          TRequired
        >;
      } catch (error) {
        if (
          error instanceof HighbeamApiError &&
          (error.statusCode === 400 || // Invalid guid
            error.statusCode === 403) && // Incorrect guid
          !params.loginRequired
        ) {
          return null as DataRequired<BusinessRep.Complete, TRequired>;
        }
        throw error;
      }
    },
  });
};

export const useBusinessQuery = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  return useQuery(useBusinessQueryOptions(params));
};

const useBusiness = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  const { data } = useSuspenseQuery(useBusinessQueryOptions(params));

  return data as DataRequired<typeof data, TRequired>;
};

export default useBusiness;
