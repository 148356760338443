import React from "react";
import { ChatElementRep } from "reps/chat/ChatElementRep";

import BulletedListChatElement from "./BulletedListChatElement";
import CategoryChatElement from "./CategoryChatElement";
import DateChatElement from "./DateChatElement";
import LineChartChatElement from "./LineChartChatElement";
import MoneyChatElement from "./MoneyChatElement";
import MonthChatElement from "./MonthChatElement";
import NumberChatElement from "./NumberChatElement";
import NumberedListChatElement from "./NumberedListChatElement";
import ParagraphChatElement from "./ParagraphChatElement";
import PercentageChatElement from "./PercentageChatElement";
import TableChatElement from "./TableChatElement";
import TextChatElement from "./TextChatElement";
import TimestampChatElement from "./TimestampChatElement";
import YearChatElement from "./YearChatElement";

type Props = {
  element: ChatElementRep;
};

const ChatElement: React.FC<Props> = ({ element }) => {
  switch (element.type) {
    case "BulletedList":
      return <BulletedListChatElement element={element} />;
    case "Category":
      return <CategoryChatElement element={element} />;
    case "Date":
      return <DateChatElement element={element} />;
    case "LineChart":
      return <LineChartChatElement element={element} />;
    case "Money":
      return <MoneyChatElement element={element} />;
    case "Month":
      return <MonthChatElement element={element} />;
    case "Number":
      return <NumberChatElement element={element} />;
    case "NumberedList":
      return <NumberedListChatElement element={element} />;
    case "Paragraph":
      return <ParagraphChatElement element={element} />;
    case "Percentage":
      return <PercentageChatElement element={element} />;
    case "Table":
      return <TableChatElement element={element} />;
    case "Text":
      return <TextChatElement element={element} />;
    case "Timestamp":
      return <TimestampChatElement element={element} />;
    case "Year":
      return <YearChatElement element={element} />;
  }
};

export default ChatElement;
