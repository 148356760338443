import { ComponentProps, ComponentRef, forwardRef, useMemo } from "react";
import ChatViewport from "ui/chat/ChatViewport";
import { useSidePanelContext } from "ui/data-display/SidePanel";

// NB(alex): Fixes resizing issue. https://linear.app/highbeam/issue/HB-6942/fix-side-panel-resizing
// Had to wrap this because the updating width would trigger a full component re-render which caused everything to lag.
const AiChatChannelPageChatViewport = forwardRef<
  ComponentRef<typeof ChatViewport>,
  ComponentProps<typeof ChatViewport>
>(({ style, ...props }, ref) => {
  const { containerWidth, width } = useSidePanelContext();
  const viewportWidth = useMemo(() => {
    if (containerWidth === 0) {
      return "100%";
    }
    return containerWidth - width;
  }, [containerWidth, width]);
  return <ChatViewport {...props} ref={ref} style={{ ...style, width: viewportWidth }} />;
});

export default AiChatChannelPageChatViewport;
