import dayjs from "dayjs";
import React from "react";
import { ChatConfigRep } from "reps/chat/ChatConfigRep";

import ChatConfigIndicator from "../../ChatConfigIndicator";

type Props = {
  chatConfig: ChatConfigRep;
};

const ChatSettingsIsolatedDataViewItemIcon: React.FC<Props> = ({ chatConfig }) => {
  const at = chatConfig.lastRefreshAt ? dayjs(chatConfig.lastRefreshAt) : null;

  if (!at) {
    return <ChatConfigIndicator status="danger">Not hydrated</ChatConfigIndicator>;
  }
  const diff = dayjs().diff(dayjs(at), "hour");
  if (diff > 24) {
    return <ChatConfigIndicator status="warning">Stale</ChatConfigIndicator>;
  }
  return <ChatConfigIndicator status="success">Hydrated</ChatConfigIndicator>;
};

export default ChatSettingsIsolatedDataViewItemIcon;
