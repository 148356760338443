import { CheckDepositListParams, CheckDepositStatus } from "@highbeam/unit-node-sdk";
import useUnitApi from "modules/unit-co-customer-token/queries/useUnitApi";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

import { ROOT_UNIT_CHECK_DEPOSIT_QUERY_KEY } from "./useRefreshUnitCheckDepositQueries";

export const INCOMING_CHECK_DEPOSIT_STATUSES = [
  "Pending",
  "Clearing",
  "PendingReview",
] satisfies CheckDepositStatus[];

export const INCOMING_CHECK_DEPOSITS_PARAMS: CheckDepositListParams = {
  status: ["Pending", "Clearing", "PendingReview"] satisfies CheckDepositStatus[],
};

export const unitCheckDepositsQueryHooks = makeQueryHooksV2({
  makeQueryKey: (params: CheckDepositListParams) => {
    return [ROOT_UNIT_CHECK_DEPOSIT_QUERY_KEY, "unitCheckDeposits", params] as const;
  },
  useQueryFnParams: useUnitApi,
  makeQueryFn: (unitApi, params) => {
    return () => {
      return unitApi.checkDeposits.list(params);
    };
  },
});

const useUnitCheckDeposits = (params: CheckDepositListParams) => {
  return unitCheckDepositsQueryHooks.useData({ params });
};

export default useUnitCheckDeposits;
