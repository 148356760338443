import HighbeamBaseApi from "api/HighbeamBaseApi";
import { MessageProfileRep } from "reps/chat/MessageProfileRep";

export default class MessageProfileApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getByMessage(messageId: string): Promise<MessageProfileRep> {
    const url = `/chat/messages/${messageId}/profile`;
    return (await this.api.get<MessageProfileRep>(url))!;
  }
}
