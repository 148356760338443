import { DASHBOARD_PAGE_CONTENT_ID } from "layouts/Dashboard";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// NB(alex): If/when we upgrade to using a data router, we can replace this react-router-dom's `<ScrollRestoration />` component. https://reactrouter.com/en/main/components/scroll-restoration
// `DISABLE_SCROLL_TO_TOP_STATE` below mimics the `preventScrollReset: true` behavior. https://reactrouter.com/en/main/components/scroll-restoration#preventing-scroll-reset

const DISABLE_SCROLL_TO_TOP_STATE_KEY = "disableScrollToTop";

const DISABLE_SCROLL_TO_TOP_STATE = {
  [DISABLE_SCROLL_TO_TOP_STATE_KEY]: true,
};

const scrollToTop = () => document.getElementById(DASHBOARD_PAGE_CONTENT_ID)?.scrollTo(0, 0);

const ScrollToTopOnNavigate = () => {
  const { pathname, state } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);

  const disableScrollToTop = state?.[DISABLE_SCROLL_TO_TOP_STATE_KEY];

  useEffect(() => {
    if (!disableScrollToTop && pathname !== prevPathname) {
      setPrevPathname(pathname);
      scrollToTop();
    }
  }, [pathname, disableScrollToTop, prevPathname]);

  return null;
};

export default ScrollToTopOnNavigate;

export { DISABLE_SCROLL_TO_TOP_STATE_KEY, DISABLE_SCROLL_TO_TOP_STATE, scrollToTop };
