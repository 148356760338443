import { LockSimple } from "@phosphor-icons/react";
import { FC } from "react";
import { Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

const AiChatYourDataIsStoredInHighbeamDisclaimer: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={cn("flex items-center justify-center gap-x-2", className)}>
      <LockSimple className="fill-grey-600" size={16} />
      <Paragraph className="text-xs text-grey-600">
        Your data is stored in Highbeam. We never allow third parties to train models on your data.
      </Paragraph>
    </div>
  );
};

export default AiChatYourDataIsStoredInHighbeamDisclaimer;
