import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import { useIsSuperusering } from "state/auth/isSuperusering";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import useBusinessMemberApi from "../api/useBusinessMemberApi";

import { ROOT_BUSINESS_MEMBERS_QUERY_KEY } from "./businessMembersQueryHooks";

type Params = {
  userGuid: string;
};

const businessMemberQueryHooks = makeQueryHooks({
  rootName: ROOT_BUSINESS_MEMBERS_QUERY_KEY,
  name: "businessMember",
  useQueryVariables: ({ userGuid }: Params) => {
    const businessGuid = useBusinessGuid();

    return {
      businessGuid,
      userGuid: userGuid,
    };
  },
  useQueryFnMaker: ({ businessGuid, userGuid }) => {
    const businessMemberApi = useBusinessMemberApi();
    return () => {
      return businessMemberApi.getByMember(businessGuid, userGuid);
    };
  },
});

export default businessMemberQueryHooks;

//
// Hooks
//

// NB(alex): This returns `null` when superusering!
export const useCurrentBusinessMember = () => {
  const authenticatedUserGuid = useUserGuid();
  return businessMemberQueryHooks.useData({ userGuid: authenticatedUserGuid });
};

export const useIsCurrentBusinessMemberOnboarded = () => {
  const businessMember = useCurrentBusinessMember();
  const isSuperusering = useIsSuperusering();

  return Boolean(businessMember?.isOnboarded) || isSuperusering;
};
