import { ChatTeardropText, X } from "@phosphor-icons/react";
import { AiChatUserAvatarByChatUserId } from "modules/chat/components/AiChatUserAvatar";
import AiChatYourDataIsStoredInHighbeamDisclaimer from "modules/chat/components/AiChatYourDataIsStoredInHighbeamDisclaimer";
import useCreateChatChannelMessageMutation from "modules/chat/mutations/useCreateChatChannelMessageMutation";
import { useChatChannelOrThrow } from "modules/chat/queries/useChatChannel";
import { useChatMessageThreadMessages } from "modules/chat/queries/useChatMessageThreadMessages";
import { useCurrentChatUserOrThrow } from "modules/chat/queries/useChatUsers";
import ChatElement from "pages/ai-chat/ChatElement";
import { FC, PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useIsSuperusering } from "state/auth/isSuperusering";
import ChatMessage from "ui/chat/ChatMessage";
import ChatMessageForm, {
  ChatMessageFormOutputs,
  useChatMessageForm,
} from "ui/chat/ChatMessageForm";
import ChatViewport from "ui/chat/ChatViewport";
import SidePanel, { useSidePanelContext } from "ui/data-display/SidePanel";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import { Heading1 } from "ui/typography";
import cn from "utils/tailwind/cn";

import AiChatSuperuserDebugInfo from "./AiChatSuperuserDebugInfo";
import getIsAtBottom from "./getIsAtBottom";
import useUnreadMessagesCount from "./useUnreadMessagesCount";

// NB(alex): Temporary hack while I figure out a better solution.
// Fixes an issue where the sidepane's chart expands but doesn't shrink.
const WidthContainer: FC<PropsWithChildren> = ({ children }) => {
  const { width } = useSidePanelContext();
  const hackHardCodedOffset = 32 + 32 + 16 + 32; // padding left + avatar + gap + padding right
  return (
    <div className="w-full" style={{ maxWidth: width - hackHardCodedOffset }}>
      {children}
    </div>
  );
};

const pxClasses = "px-4 @md:px-6 @lg:px-8";

type Props = {
  channelId: string;
  messageId: string;
};

const ChatMessageSidePanelContent: FC<Props> = ({ channelId, messageId }) => {
  const messages = useChatMessageThreadMessages({
    channelId,
    parentMessageId: messageId,
  });

  // Disable the form if superusering into another chat user's channel.
  const channel = useChatChannelOrThrow({ channelId });
  const currentChatUser = useCurrentChatUserOrThrow();
  const isSuperusering = useIsSuperusering();
  const isInOwnChannel = channel.chatUserIds.includes(currentChatUser.id);
  const isSuperuseringInOtherUserChannel = isSuperusering && !isInOwnChannel;

  const form = useChatMessageForm({
    disabled: isSuperuseringInOtherUserChannel,
  });

  const { mutateAsync: createChatChannelMessage, isPending } = useCreateChatChannelMessageMutation({
    onSuccess: (_data) => {
      form.reset();
    },
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const scrollToBottom = useCallback(() => {
    scrollContainerRef.current?.scrollTo({
      top: scrollContainerRef.current?.scrollHeight,
      behavior: "instant",
    });
  }, []);

  const { unreadMessagesCount, resetUnreadMessagesCount } = useUnreadMessagesCount({
    messages,
    currentChatUserId: currentChatUser.id,
    scrollContainerElement: scrollContainerRef.current,
  });

  // Scroll to the most recent message and focus the textarea.
  useEffect(() => {
    scrollToBottom();
    textAreaRef.current?.focus();
  }, [scrollToBottom, messageId]);

  // Scroll to the most recent message when a new message comes in if scrolled is already at the bottom.
  useEffect(() => {
    if (scrollContainerRef.current) {
      const isAtBottom = getIsAtBottom(scrollContainerRef.current);
      if (isAtBottom) {
        scrollToBottom();
      }
    }
  }, [messages, scrollToBottom]);

  // Scroll to the most recent message when a new message comes in if scrolled is already at the bottom.
  useEffect(() => {
    if (scrollContainerRef.current) {
      const isAtBottom = getIsAtBottom(scrollContainerRef.current);
      if (isAtBottom) {
        scrollToBottom();
      }
    }
  }, [messages, scrollToBottom]);

  const onSubmit = useCallback(
    async (data: ChatMessageFormOutputs) => {
      if (isPending) return;

      if (isSuperuseringInOtherUserChannel) {
        return notify("error", "Superusers cannot send chats in other users’ channels.");
      }

      await createChatChannelMessage({
        channelId: channelId,
        content: data.content,
        parentId: messageId,
      });
    },
    [channelId, messageId, isPending, createChatChannelMessage, isSuperuseringInOtherUserChannel]
  );

  return (
    <div className="flex h-full flex-col @container">
      <div
        className={cn(
          "flex items-center justify-between border-b border-b-grey-100 py-5",
          pxClasses
        )}
      >
        <div className="flex h-8 items-center gap-x-3">
          <ChatTeardropText size={24} className="-scale-x-100 text-grey-800" weight="light" />
          <Heading1 className="text-md font-bold text-grey-800">Thread</Heading1>
        </div>

        <SidePanel.Trigger>
          {({ onOpenToggle }) => (
            <Button
              variant="ghost"
              paddingVariant="square"
              size="sm"
              onClick={() => onOpenToggle()}
            >
              <X size={16} />
            </Button>
          )}
        </SidePanel.Trigger>
      </div>

      <ChatViewport>
        <ChatViewport.ScrollContainer
          className="overflow-x-auto py-7"
          ref={scrollContainerRef}
          onScroll={(e) => {
            if (unreadMessagesCount) {
              const isAtBottom = getIsAtBottom(e.target as HTMLDivElement);
              if (isAtBottom) {
                resetUnreadMessagesCount();
              }
            }
          }}
        >
          <div className={cn("pb-4 @container")}>
            {[...messages].reverse().map((message) => {
              return (
                <div key={message.id}>
                  {message.contents.map((content, contentIndex) => {
                    const isLastContentBlock = contentIndex === message.contents.length - 1;
                    const showAiChatSuperuserDebugInfo = isSuperusering && isLastContentBlock;

                    return (
                      <ChatMessage
                        key={contentIndex} // Index is static.
                        className="hover:bg-grey-50/50"
                      >
                        {content.elements.map((element, elementIndex) => (
                          <ChatMessage.Block
                            key={elementIndex} // Index is static.
                            avatar={
                              elementIndex === 0 ? (
                                <AiChatUserAvatarByChatUserId chatUserId={content.senderId} />
                              ) : undefined
                            }
                            className={pxClasses}
                          >
                            <WidthContainer>
                              <ChatElement element={element} />
                            </WidthContainer>
                          </ChatMessage.Block>
                        ))}

                        {message.contents.length === 1 && (
                          <ChatMessage.BlockLoading className={pxClasses} />
                        )}

                        {showAiChatSuperuserDebugInfo && (
                          <AiChatSuperuserDebugInfo messageId={message.id}>
                            <ChatMessage.Block className={pxClasses}>
                              <AiChatSuperuserDebugInfo.Button />
                            </ChatMessage.Block>
                            <AiChatSuperuserDebugInfo.Content />
                          </AiChatSuperuserDebugInfo>
                        )}
                      </ChatMessage>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </ChatViewport.ScrollContainer>

        <ChatViewport.InputContainer className={pxClasses}>
          {Boolean(unreadMessagesCount) && (
            <ChatViewport.NewMessageButton
              unreadMessagesCount={unreadMessagesCount}
              onClick={() => {
                scrollToBottom();
              }}
            />
          )}

          <ChatMessageForm onSubmit={onSubmit} form={form} textAreaRef={textAreaRef} />

          <AiChatYourDataIsStoredInHighbeamDisclaimer className="px-2 py-5" />
        </ChatViewport.InputContainer>
      </ChatViewport>
    </div>
  );
};

export default ChatMessageSidePanelContent;
