import dayjs from "dayjs";
import React from "react";
import { YearChatElementRep } from "reps/chat/ChatElementRep";

type Props = {
  element: YearChatElementRep;
};

const YearChatElement: React.FC<Props> = ({ element }) => {
  return <span>{dayjs(element.value).tz("America/New_York").format("YYYY")}</span>;
};

export default YearChatElement;
