import { ArrowsClockwise, Check } from "@phosphor-icons/react";
import { FEATURE_FLAGS } from "flags";
import useFeatureFlag from "modules/feature-flags/hooks/useFeatureFlag";
import { FC, ReactNode } from "react";
import Card from "ui/data-display/Card";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import Tooltip from "ui/overlay/Tooltip";
import { Heading4, Paragraph, Span } from "ui/typography";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";
import cn from "utils/tailwind/cn";

// NB(alex): This can probably be consolidated with `AddIntegration`.

type Props = {
  name: string;
  hasSynced?: boolean;
  logo?: ReactNode;
  onDisconnect?: () => void;
  className?: string;
};

const RutterConnectionCard: FC<Props> = ({ name, hasSynced, logo, onDisconnect, className }) => {
  const isAllowedToConnectStores = useIsAllowedToConnectStores();
  const canDisconnectStoreInSettings = useFeatureFlag(
    FEATURE_FLAGS["allow-store-disconnection-in-settings"]
  );
  const disabledDisconnect = !canDisconnectStoreInSettings || !isAllowedToConnectStores;

  return (
    <Card
      className={cn(
        "flex min-h-40 w-full min-w-full flex-col items-start justify-between border border-grey-200 p-5 tablet:min-w-80",
        className
      )}
      shadow="xs"
    >
      <div>
        <div className="mb-3 flex items-center gap-x-2">
          {logo}
          <Heading4>{name}</Heading4>
        </div>

        <Paragraph className="mt-2 flex items-center gap-x-2 text-grey-600">
          {hasSynced ? (
            <>
              <Check size={16} className="rounded-full bg-green-400 p-0.5" color="white" />
              <Paragraph className="text-sm">Connected</Paragraph>
            </>
          ) : (
            <Tooltip>
              <Tooltip.Trigger>
                <Span className="flex items-center gap-x-2">
                  <ArrowsClockwise size={16} className="bg-white" />
                  <Paragraph className="text-sm">Syncing store data</Paragraph>
                </Span>
              </Tooltip.Trigger>

              <Tooltip.Content>
                <Paragraph>Sync can take from an average of 24h up to a week.</Paragraph>
              </Tooltip.Content>
            </Tooltip>
          )}
        </Paragraph>
      </div>

      {onDisconnect && (
        <ButtonWithTooltip
          fullWidth
          variant={disabledDisconnect ? "danger" : "tertiary"}
          onClick={onDisconnect}
          disabled={disabledDisconnect}
          tooltip={
            disabledDisconnect && (
              <DisconnectStoreTooltip
                title={name}
                isAllowedToConnectStore={isAllowedToConnectStores}
              />
            )
          }
        >
          Disconnect
        </ButtonWithTooltip>
      )}
    </Card>
  );
};

const DisconnectStoreTooltip: FC<{
  title: string;
  isAllowedToConnectStore: boolean;
}> = ({ title, isAllowedToConnectStore }) => (
  <Paragraph>
    {!isAllowedToConnectStore ? (
      <>You don’t have permission to disconnect stores.</>
    ) : (
      <>If you want to disconnect {title}, please reach out to Highbeam support.</>
    )}
  </Paragraph>
);

export default RutterConnectionCard;
