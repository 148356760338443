import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import BankAccountRep from "reps/BankAccountRep";
import { maskBankAccountAccountNumber } from "utils/account";
import useHasPermission from "utils/permissions/useHasPermission";
import useIsAllowedToViewAccountNumbers from "utils/permissions/useIsAllowedToViewAccountNumbers";
import makeQueryHooks from "utils/react-query/makeQueryHooks";
import useRefreshQuery, { UseRefreshQueryOptions } from "utils/react-query/useRefreshQuery";

import useBankAccountApi from "../api/useBankAccountApi";
import sortBankAccounts from "../utils/sortBankAccounts";

export const ROOT_BANK_ACCOUNTS_QUERY_KEY = "bankAccounts-root";

export const useRefreshAllBankAccountQueries = (options?: UseRefreshQueryOptions) => {
  return useRefreshQuery([ROOT_BANK_ACCOUNTS_QUERY_KEY], options);
};

export type Status = "all" | "closed" | "open";

const getStatuses = (status: Status): BankAccountRep.Status[] => {
  switch (status) {
    case "all":
      return [
        BankAccountRep.Status.OPEN,
        BankAccountRep.Status.CLOSED,
        BankAccountRep.Status.FROZEN,
      ];
    case "closed":
      return [BankAccountRep.Status.CLOSED];
    case "open":
      return [BankAccountRep.Status.OPEN, BankAccountRep.Status.FROZEN];
  }
};

type Params = {
  status: Status;
};

const bankAccountsQueryHooks = makeQueryHooks({
  rootName: ROOT_BANK_ACCOUNTS_QUERY_KEY,
  name: "bankAccounts",
  useQueryVariables: (params: Params) => {
    const businessGuid = useBusinessGuid();
    const isAllowedToViewAccountNumbers = useIsAllowedToViewAccountNumbers();

    // NB(alex): Unsure about this pattern, please don't copy it yet.
    const hasPermission = useHasPermission("bankAccount:read");

    return {
      businessGuid,
      hasPermission,
      isAllowedToViewAccountNumbers,
      statuses: getStatuses(params.status),
    };
  },
  useQueryFnMaker: ({ statuses, hasPermission, isAllowedToViewAccountNumbers, businessGuid }) => {
    const bankAccountApi = useBankAccountApi();

    return async () => {
      if (!hasPermission) {
        return [];
      }

      const bankAccounts = await bankAccountApi.getByBusiness(businessGuid);

      // NB(alex): We should mask account numbers on the backend, but this simulates the behavior for now.
      const bankAccountsWithMaskedAccountNumbers = isAllowedToViewAccountNumbers
        ? bankAccounts
        : bankAccounts.map(maskBankAccountAccountNumber);

      const bankAccountsFilteredByStatus = bankAccountsWithMaskedAccountNumbers.filter(
        (bankAccount) => statuses.includes(bankAccount.status)
      );

      return bankAccountsFilteredByStatus.sort(sortBankAccounts);
    };
  },
});

export default bankAccountsQueryHooks;

// Hooks

export const usePaymentEnabledBankAccounts = () => {
  return bankAccountsQueryHooks.useData({
    status: "open",
    select: (bankAccounts) => {
      return bankAccounts.filter((account) => {
        return (
          (account.highbeamType.includeInSendMoney && account.threadAccount) || account.isPrimary
        );
      });
    },
  });
};
