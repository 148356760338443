import useUserGuid from "modules/jwt/queries/useUserGuid";
import { MessageCreatorRep, MessageRep } from "reps/chat/MessageRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import { useRefreshChatChannelMessages } from "../queries/useChatChannelMessages";
import { useRefreshChatMessageThreadMessages } from "../queries/useChatMessageThreadMessages";

type Variables = Omit<MessageCreatorRep, "userGuid">;

const useCreateChatChannelMessageMutation = (
  additionalOptions?: MutationAdditionalOptions<MessageRep, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const userGuid = useUserGuid();
  const refreshChatChannelMessages = useRefreshChatChannelMessages();
  const refreshChatMessageThreadMessages = useRefreshChatMessageThreadMessages();

  return useMutationWithDefaults(
    {
      mutationFn: (variables: Variables) => {
        return highbeamApi.message.create({ userGuid, ...variables });
      },
      onSuccess: async () => {
        await Promise.all([refreshChatChannelMessages(), refreshChatMessageThreadMessages()]);
      },
    },
    additionalOptions ?? {}
  );
};

export default useCreateChatChannelMessageMutation;
