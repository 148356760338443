import { CaretRight, ChatTeardropText } from "@phosphor-icons/react";
import { ComponentPropsWithoutRef, ComponentRef, forwardRef, ReactNode } from "react";
import Button from "ui/inputs/Button";
import { Span } from "ui/typography";
import { pluralize } from "utils/string";
import cn from "utils/tailwind/cn";

type Props = Omit<ComponentPropsWithoutRef<typeof Button>, "children"> & {
  replyCount: number;
  status?: ReactNode;
};

const ReplyInThreadButton = forwardRef<ComponentRef<typeof Button>, Props>(
  ({ replyCount, status, className, ...props }, forwardedRef) => {
    const hasReplies = replyCount > 0;

    return (
      <Button
        size="sm"
        className={cn(
          "group flex items-center px-2.5 text-xs font-regular text-grey-800 hover:bg-grey-50",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <ChatTeardropText size={20} className="mr-1 -scale-x-100 text-purple-500" weight="light" />
        <Span>
          <Span className="mr-1 font-medium text-purple-500">
            {hasReplies ? `${pluralize(replyCount, "Reply", "Replies")}` : "Ask a follow-up"}
          </Span>
          in thread
          {status && (
            <>
              <Span className="mx-2"> • </Span> {status}
            </>
          )}
        </Span>
        <CaretRight
          size={12}
          className="invisible ml-auto shrink-0 text-grey-400 group-hover:visible"
          weight="bold"
        />
      </Button>
    );
  }
);

export default ReplyInThreadButton;
