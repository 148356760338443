import React from "react";
import { MoneyChatElementRep } from "reps/chat/ChatElementRep";
import MoneyAmount from "ui/data-display/money/MoneyAmountV2";
import cn from "utils/tailwind/cn";

import { useParagraphChatElementContext } from "./ParagraphChatElement";

type Props = {
  element: MoneyChatElementRep;
};

const MoneyChatElement: React.FC<Props> = ({ element }) => {
  const { isInParagraph } = useParagraphChatElementContext();

  return (
    <MoneyAmount
      amount={element.amount.toString()}
      currencyCode="USD"
      showCurrencySymbol
      showCents={element.showCents}
      className={cn({ "font-bold": isInParagraph })}
    />
  );
};

export default MoneyChatElement;
