import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const chatMessageFormSchema = z.object({
  content: z.string().nonempty(),
});

export type ChatMessageFormInputs = z.input<typeof chatMessageFormSchema>;
export type ChatMessageFormOutputs = z.output<typeof chatMessageFormSchema>;

type Params = {
  disabled?: boolean;
};

const useChatMessageForm = (params?: Params) =>
  useForm<ChatMessageFormInputs>({
    resolver: zodResolver(chatMessageFormSchema),
    defaultValues: {
      content: "",
    } satisfies ChatMessageFormInputs,
    ...params,
  });

export type UseChatMessageFormReturn = ReturnType<typeof useChatMessageForm>;

export default useChatMessageForm;
