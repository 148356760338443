import CommandPaletteDialog from "modules/command-palette/CommandPaletteDialog";
import useDocumentKeyboardEvent from "utils/browser/useDocumentKeyboardEvent";

import useCommandPaletteState from "../useCommandPaletteState";

const CommandPalette = () => {
  const [{ isOpen }, setCommandPaletteState] = useCommandPaletteState();

  useDocumentKeyboardEvent((e: KeyboardEvent) => {
    if (e.key === "k" && e.metaKey && !e.shiftKey) {
      e.preventDefault();
      setCommandPaletteState(({ isOpen }) => ({ isOpen: !isOpen }));
    }
  }, []);

  const handleClose = () => {
    setCommandPaletteState({ isOpen: false });
  };

  return <CommandPaletteDialog isOpen={isOpen} onClose={handleClose} />;
};

export default CommandPalette;
