import { Dayjs } from "dayjs";

import { startOfBankingDay, startOfYear, startOfQuarter } from "./date";

// "Relative" timeframes, used for things like scoping balance history to a
// certain time period, are always relative to the current date and are interpreted
// to include the current date. For example, "last 7 days" covers *today* and the
// previous 6 days.
export enum RelativeTimeframe {
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_30_DAYS = "LAST_30_DAYS",
  THIS_QUARTER = "THIS_QUARTER",
  YEAR_TO_DATE = "YEAR_TO_DATE",
  LAST_12_MONTHS = "LAST_12_MONTHS",
}

export const DEFAULT_RELATIVE_TIMEFRAME = RelativeTimeframe.LAST_30_DAYS;

export const timeframeToNumDays = (timeframe: RelativeTimeframe): number => {
  switch (timeframe) {
    case RelativeTimeframe.LAST_7_DAYS:
      return 7;
    case RelativeTimeframe.LAST_30_DAYS:
      return 30;
    case RelativeTimeframe.THIS_QUARTER:
      return startOfBankingDay().diff(startOfQuarter(), "days") + 1;
    case RelativeTimeframe.YEAR_TO_DATE:
      return startOfBankingDay().diff(startOfYear(), "days") + 1;
    case RelativeTimeframe.LAST_12_MONTHS:
      const start = startOfBankingDay();
      const last12Months = start.subtract(12, "months");
      return start.diff(last12Months, "days") + 1;
  }
};

export const timeframeToSince = (timeframe: RelativeTimeframe): Dayjs =>
  startOfBankingDay().subtract(timeframeToNumDays(timeframe) - 1, "days");

export const timeframeToNumDaysBetweenTicks = (timeframe: RelativeTimeframe): number => {
  switch (timeframe) {
    case RelativeTimeframe.LAST_7_DAYS:
      return 1;
    case RelativeTimeframe.LAST_30_DAYS:
      return 10;
    case RelativeTimeframe.LAST_12_MONTHS:
      return 30;
    case RelativeTimeframe.THIS_QUARTER:
    case RelativeTimeframe.YEAR_TO_DATE:
      return Math.ceil(timeframeToNumDays(timeframe) / 10);
  }
};
