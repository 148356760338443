import { FC } from "react";
import Listbox from "ui/inputs/Listbox";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";
import { RelativeTimeframe } from "utils/timeframe";

type RelativeTimeframeSelectProps = {
  value: RelativeTimeframe;
  onChange: (newValue: RelativeTimeframe) => void;
};

const RelativeTimeframeSelect: FC<RelativeTimeframeSelectProps> = ({ value, onChange }) => (
  <Listbox
    variant="minimal"
    ghost
    value={value}
    onValueChange={(newValue) => onChange(newValue as RelativeTimeframe)}
  >
    <Listbox.Field>
      <Listbox.Label>Timeframe</Listbox.Label>
      <Listbox.Trigger className="h-auto py-2 text-xs font-medium hover:bg-grey-50 active:shadow-inset [&>span]:pr-1" />
    </Listbox.Field>
    <Listbox.Menu align="end">
      <Listbox.Item value={RelativeTimeframe.LAST_7_DAYS}>Last 7 days</Listbox.Item>
      <Listbox.Item value={RelativeTimeframe.LAST_30_DAYS}>Last 30 days</Listbox.Item>
      <Listbox.Item value={RelativeTimeframe.THIS_QUARTER}>This quarter</Listbox.Item>
      <Listbox.Item value={RelativeTimeframe.YEAR_TO_DATE}>Year-to-date</Listbox.Item>
      <Listbox.Item value={RelativeTimeframe.LAST_12_MONTHS}>Last 12 months</Listbox.Item>
    </Listbox.Menu>
  </Listbox>
);

type Props = PropsWithChildrenAndClassName;

const ChartTools: FC<Props> = ({ children, className }) => (
  <div className={cn("flex items-center justify-end", className)}>{children}</div>
);

export default Object.assign(ChartTools, {
  RelativeTimeframeSelect: RelativeTimeframeSelect,
});
