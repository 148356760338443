import dayjs from "dayjs";
import React from "react";
import { DateChatElementRep } from "reps/chat/ChatElementRep";

type Props = {
  element: DateChatElementRep;
};

const DateChatElement: React.FC<Props> = ({ element }) => {
  return <span>{dayjs(element.value).tz("America/New_York").format("MMM D, YYYY")}</span>;
};

export default DateChatElement;
