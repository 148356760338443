import { Export } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { snakeCase } from "lodash-es";
import useDownloadTableChatElementAsCsv from "modules/chat/hook/useDownloadTableChatElementAsCsv";
import React, { useMemo } from "react";
import { ParagraphChatElementRep, TableChatElementRep } from "reps/chat/ChatElementRep";
import EmptyState from "ui/data-display/EmptyState";
import ScrollWithInsetShadow from "ui/data-display/ScrollWithInsetShadow";
import Button from "ui/inputs/Button";
import NarrowTable from "ui/table/NarrowTable";
import { Column } from "ui/table/Table";
import { Heading4 } from "ui/typography";

import ParagraphChatElement from "./ParagraphChatElement";

import ChatElement from ".";

type Props = {
  element: TableChatElementRep;
};

const TableChatElement: React.FC<Props> = ({ element }) => {
  const columns = useMemo(() => {
    return element.columns.map((column, i) => {
      return {
        title: column.header,
        cellRender: (row) => {
          const value = row[i];
          return <ParagraphChatElement element={value} />;
        },
      } satisfies Column<ParagraphChatElementRep[]>;
    });
  }, [element.columns]);

  const data = element.values;

  const downloadTableChatElementAsCsv = useDownloadTableChatElementAsCsv();

  return (
    <div className="w-full overflow-hidden rounded-2xl border border-grey-200">
      <div className="flex items-center justify-between px-5 pb-5 pt-5">
        <div>
          <Heading4 className="font-medium">{element.name}</Heading4>
        </div>

        <Button
          variant="tertiary"
          size="xs"
          onClick={() =>
            downloadTableChatElementAsCsv(
              element,
              `highbeam_ai-${dayjs().format("YYYY_MM_DD")}-${snakeCase(element.name)}`
            )
          }
        >
          <Export /> Export
        </Button>
      </div>

      <ScrollWithInsetShadow orientation="horizontal" className="border-t border-grey-100">
        <NarrowTable>
          <NarrowTable.Head>
            <NarrowTable.Row>
              {columns.map((column, i) => (
                <NarrowTable.HeadCell key={i}>{column.title}</NarrowTable.HeadCell>
              ))}
            </NarrowTable.Row>
          </NarrowTable.Head>

          <NarrowTable.Body>
            {data.length > 0 ? (
              <>
                {data.map((row, i) => (
                  <NarrowTable.Row key={i}>
                    {row.map((cell, j) => (
                      <NarrowTable.Cell key={j}>{<ChatElement element={cell} />}</NarrowTable.Cell>
                    ))}
                  </NarrowTable.Row>
                ))}
              </>
            ) : (
              <div className="px-12 py-10">
                <EmptyState body={<EmptyState.PrimaryText>No results</EmptyState.PrimaryText>} />
              </div>
            )}
          </NarrowTable.Body>
        </NarrowTable>
      </ScrollWithInsetShadow>
    </div>
  );
};

export default TableChatElement;
