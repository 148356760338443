import { Smiley, SmileyAngry, SmileyMeh, SmileySad } from "@phosphor-icons/react";
import { FC } from "react";

type Props = {
  variant: "sad" | "meh" | "happy" | "angry";
};

const MessageLogIndicator: FC<Props> = ({ variant }) => {
  switch (variant) {
    case "sad":
      return <SmileySad className="size-5 shrink-0 text-red-600" />;
    case "meh":
      return <SmileyMeh className="size-5 shrink-0 text-yellow-700" />;
    case "happy":
      return <Smiley className="size-5 shrink-0 text-green-600" />;
    case "angry":
      return <SmileyAngry className="size-5 shrink-0 text-purple-500" />;
  }
};

export default MessageLogIndicator;
