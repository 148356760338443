import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooksV2 from "utils/react-query/makeQueryHooksV2";

type Params = {
  businessGuid: string;
};

export const chatChannelsByBusiness = makeQueryHooksV2({
  useDefaultParams: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  makeQueryKey: (params: Params) => ["chat", "channels", "business", params],
  useQueryFnParams: useHighbeamApi,
  makeQueryFn: (highbeamApi, { businessGuid }) => {
    return () => {
      return highbeamApi.channelApi.listByBusiness(businessGuid);
    };
  },
});
