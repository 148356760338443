import { ArrowDown } from "@phosphor-icons/react";
import { ComponentProps, ComponentRef, forwardRef } from "react";
import Button from "ui/inputs/Button";
import { pluralize } from "utils/string";
import cn from "utils/tailwind/cn";

const ChatViewport = forwardRef<ComponentRef<"div">, ComponentProps<"div">>(
  ({ children, className, ...props }, ref) => {
    return (
      <div className={cn("flex h-0 flex-grow", className)} {...props} ref={ref}>
        <div className="flex flex-grow flex-col">{children}</div>
      </div>
    );
  }
);

const ChatViewportScrollContainer = forwardRef<ComponentRef<"div">, ComponentProps<"div">>(
  ({ children, className, ...props }, ref) => {
    return (
      <div className={cn("flex-grow overflow-y-auto", className)} {...props} ref={ref}>
        {children}
      </div>
    );
  }
);

const ChatViewportInputContainer = forwardRef<ComponentRef<"div">, ComponentProps<"div">>(
  ({ children, className, ...props }, ref) => {
    return (
      <div className={cn("relative", className)} {...props} ref={ref}>
        {children}
      </div>
    );
  }
);

type ChatViewportNewMessageButtonProps = ComponentProps<typeof Button> & {
  unreadMessagesCount: number;
};

const ChatViewportNewMessageButton = forwardRef<
  ComponentRef<typeof Button>,
  ChatViewportNewMessageButtonProps
>(({ className, unreadMessagesCount, ...props }, ref) => {
  return (
    <Button
      variant="secondary"
      size="sm"
      className={cn(
        "pointer-events-auto absolute -top-4 left-0 right-0 z-10 mx-auto w-fit -translate-y-full rounded-full",
        className
      )}
      {...props}
      ref={ref}
    >
      <ArrowDown size={16} /> {pluralize(unreadMessagesCount, "new message")}
    </Button>
  );
});

export default Object.assign(ChatViewport, {
  ScrollContainer: ChatViewportScrollContainer,
  InputContainer: ChatViewportInputContainer,
  NewMessageButton: ChatViewportNewMessageButton,
});
