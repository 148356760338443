import { Dialog, DialogBackdrop, Transition, TransitionChild } from "@headlessui/react";
import { FC, Fragment, ReactNode, RefObject, Suspense, useId, useImperativeHandle } from "react";
import cn from "utils/tailwind/cn";
import { useTransition } from "utils/transitions";

import ModalBody, { ModalParagraph } from "./ModalBody";
import ModalFooter, { ModalCloseButton, ModalSubmitButton } from "./ModalFooter";
import ModalForm from "./ModalForm";
import ModalHeader from "./ModalHeader";
import ModalLoadingFallback from "./ModalLoadingFallback";
import { ModalContextProvider, ModalSize } from "./useModalContext";

const backdropTransitionClasses = {
  enter: "duration-150 transition-opacity",
  enterFrom: "opacity-0",
  enterTo: "opacity-1",
  leave: "duration-150 transition-opacity",
  leaveFrom: "opacity-1",
  leaveTo: "opacity-0",
};

export const modalTransitionClasses = {
  enter: "duration-150 transition-[opacity,transform]",
  enterFrom: "opacity-0 transform scale-75",
  leave: "duration-150 transition-[opacity,transform]",
  leaveTo: "opacity-0 transform scale-75",
};

export type ModalRef = {
  closeModalWithAnimation: () => void;
};

type Props = {
  onClose: () => void;
  size?: ModalSize;
  children: ReactNode;
  className?: string;
  suspenseFallback?: ReactNode;
  modalRef?: RefObject<ModalRef>;

  /**
   * HACK: Set this to `true` if you have a dropdown component inside the modal body, but gets cut off by the modal's overflow styles.
   * If you enable this, the overflow will no longer scroll, so any overflow will get cut off. We will have to use a different underlying modal component to get around this limitation (I think).
   */
  dropdownOverflowHack?: boolean;
};

const ModalV4: FC<Props> = ({
  children,
  onClose: onCloseProp,
  suspenseFallback = <ModalLoadingFallback />,
  className,
  dropdownOverflowHack,
  size = "standard",
  modalRef,
}) => {
  const {
    show,
    setShow,
    handleClose: closeModalWithAnimation,
  } = useTransition(true, {
    initiateClose: () => setShow(false),
    onClose: onCloseProp,
  });

  useImperativeHandle(modalRef, () => ({
    closeModalWithAnimation,
  }));

  const formId = useId();

  return (
    <ModalContextProvider
      closeModalWithAnimation={closeModalWithAnimation}
      size={size}
      formId={formId}
    >
      <Transition show={show} as={Fragment}>
        <Dialog onClose={closeModalWithAnimation}>
          <TransitionChild
            as={Fragment}
            enter={backdropTransitionClasses.enter}
            enterFrom={backdropTransitionClasses.enterFrom}
            enterTo={backdropTransitionClasses.enterTo}
            leave={backdropTransitionClasses.leave}
            leaveFrom={backdropTransitionClasses.leaveFrom}
            leaveTo={backdropTransitionClasses.leaveTo}
          >
            <DialogBackdrop
              className="fixed inset-0 z-modal-backdrop overflow-hidden bg-black/20"
              onClick={closeModalWithAnimation}
            />
          </TransitionChild>

          <TransitionChild
            as="div"
            className="pointer-events-none fixed inset-0 z-modal flex flex-col items-center justify-center"
            enter={modalTransitionClasses.enter}
            enterFrom={modalTransitionClasses.enterFrom}
            leave={modalTransitionClasses.leave}
            leaveTo={modalTransitionClasses.leaveTo}
          >
            <Suspense fallback={suspenseFallback}>
              <div
                className={cn(
                  "pointer-events-auto flex h-full w-full flex-col overflow-y-auto bg-white shadow-md mobile-phone:my-16 mobile-phone:h-auto mobile-phone:w-[600px] mobile-phone:rounded-lg",
                  {
                    "h-full w-full mobile-phone:w-[350px]": size === "small",
                    "tablet:overflow-y-visible": dropdownOverflowHack,
                  },
                  className
                )}
              >
                {children}
              </div>
            </Suspense>
          </TransitionChild>
        </Dialog>
      </Transition>
    </ModalContextProvider>
  );
};

export default Object.assign(ModalV4, {
  Form: ModalForm,
  Header: ModalHeader,
  Body: ModalBody,
  Paragraph: ModalParagraph,
  Footer: ModalFooter,
  CloseButton: ModalCloseButton,
  SubmitButton: ModalSubmitButton,
  LoadingFallback: ModalLoadingFallback,
});
