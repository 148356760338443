import React from "react";
import { NumberChatElementRep } from "reps/chat/ChatElementRep";

type Props = {
  element: NumberChatElementRep;
};

const NumberChatElement: React.FC<Props> = ({ element }) => {
  return <span>{element.value.toFixed(element.decimalPlaces)}</span>;
};

export default NumberChatElement;
