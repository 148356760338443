import { useSuspenseQuery } from "@tanstack/react-query";
import {
  INCOMING_CHECK_DEPOSIT_STATUSES,
  unitCheckDepositsQueryHooks,
} from "modules/unit-co-check-deposits/queries/useUnitCheckDeposits";
import useUnitCoPaymentsQueryOptions, {
  INCOMING_PAYMENT_PARAMS,
} from "modules/unit-co-payments/queries/useUnitCoPaymentsQueryOptions";
import useUnitCoReceivedPaymentsQueryOptions from "modules/unit-co-received-payments/queries/useUnitCoReceivedPaymentsQueryOptions";

import { IncomingPayment } from "../types";
import getIncomingPaymentEstDate from "../utils/getIncomingPaymentEstDate";

type Params = {
  accountId?: string;
};

const useIncomingPayments = ({ accountId }: Params = {}) => {
  const unitCheckDeposits = unitCheckDepositsQueryHooks.useData({
    params: {
      accountId,
      status: INCOMING_CHECK_DEPOSIT_STATUSES,
    },
    select: (data) => data.data,
  });

  // Only needed for Plaid transfers
  const { data: unitPayments } = useSuspenseQuery(
    useUnitCoPaymentsQueryOptions({
      accountId,
      ...INCOMING_PAYMENT_PARAMS,
    })
  );

  const { data: receivedPayments } = useSuspenseQuery(
    useUnitCoReceivedPaymentsQueryOptions({
      accountId: accountId,
      status: ["Advanced", "Pending", "PendingReview"],
    })
  );

  const incomingPayments: IncomingPayment[] = [
    ...unitCheckDeposits,
    ...unitPayments,
    ...receivedPayments,
  ];

  const sortedIncomingPayments = incomingPayments.sort((a, b) => {
    // Sort reverse chronologically. Nulls first. If both null, put later createdAt first

    const estA = getIncomingPaymentEstDate(a);
    const estB = getIncomingPaymentEstDate(b);

    if (!estA && !estB) {
      return b.attributes.createdAt.localeCompare(a.attributes.createdAt);
    }
    if (!estA) {
      return -1;
    }
    if (!estB) {
      return 1;
    }
    return estB.localeCompare(estA);
  });

  return sortedIncomingPayments;
};

export default useIncomingPayments;
