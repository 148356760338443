import dayjs from "dayjs";
import { formatValue } from "react-currency-input-field";
import { formatNumberCompact } from "utils/numbers";

const DATE_FORMAT = "MMM D, ’YY";

/**
 * X Axis
 */

type XAxisYearMonthDayParams = {
  value: string;
  type: "YYYY-MM-DD";
};

export type XAxisParams = XAxisYearMonthDayParams;

export type XAxisType = XAxisParams["type"];

type FormatXAxisValueParams = XAxisParams;

export const formatXAxisValue = ({ value, type }: FormatXAxisValueParams): string => {
  switch (type) {
    case "YYYY-MM-DD":
      const valueAsDayjs = dayjs(value, "YYYY-MM-DD", true);
      if (valueAsDayjs.isValid()) {
        return valueAsDayjs.format(DATE_FORMAT);
      }
      throw new Error("Invalid date string in `formatXAxisValue`");
  }
};

/**
 * Y Axis
 */

export type YAxisNumberParams = {
  value: number | string;
  type: "number";
  compact?: boolean;
};

export type YAxisMoneyParams = {
  value: number | string;
  type: "money";
  compact?: boolean;
};

export type YAxisParams = YAxisNumberParams | YAxisMoneyParams;

export type YAxisType = YAxisParams["type"];

export const checkIsValidYAxisValue = (
  value: unknown,
  type: YAxisType
): value is YAxisParams["value"] => {
  switch (type) {
    case "money":
    case "number":
      return typeof value === "number" || typeof value === "string";
  }
};

type FormatYAxisValueParams = YAxisParams;

export const formatYAxisValue = (params: FormatYAxisValueParams): string => {
  switch (params.type) {
    case "money":
      const numberAsString = params.compact
        ? formatNumberCompact(Number(params.value))
        : formatValue({
            value: params.value.toString(),
            decimalScale: 2, // Assumes USD. We'd need to pass in a currency if we want to support other currencies.
          });
      return `$${numberAsString}`;
    case "number":
      return params.compact ? formatNumberCompact(Number(params.value)) : params.value.toString();
  }
};
