import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useMfa from "modules/mfa/useMfa";
import { notify } from "ui/feedback/Toast";

import useBankAccountApi from "../api/useBankAccountApi";
import { useRefreshAllBankAccountQueries } from "../queries/bankAccountsQueryHooks";

const useCreateBankAccountMutation = () => {
  const bankAccountApi = useBankAccountApi();
  const businessGuid = useBusinessGuid();
  const { mfa } = useMfa();

  const refreshBankAccountsQuery = useRefreshAllBankAccountQueries({
    refetchType: "all",
  });

  return useMutation({
    mutationFn: async (accountName: string) => {
      await mfa();
      return bankAccountApi.create({ businessGuid, name: accountName });
    },
    onError: () => {
      notify("error", "Something went wrong creating your account.");
    },
    onSuccess: async () => {
      await refreshBankAccountsQuery();
    },
  });
};

export default useCreateBankAccountMutation;
