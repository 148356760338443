import MessageLogRepDisplay from "modules/chat/components/MessageLogRepDisplay";
import { chatMessageDebugInfoQueryHooks } from "modules/chat/queries/useChatMessageDebugInfo";
import { Dispatch, FC, PropsWithChildren, SetStateAction, useState } from "react";
import Button from "ui/inputs/Button";
import createComponentContext from "utils/react-helpers/createComponentContext";

const [AiChatSuperuserDebugInfoProvider, useAiChatSuperuserDebugInfoContext] =
  createComponentContext<{
    messageId: string;
    showDebugInfo: boolean;
    setShowDebugInfo: Dispatch<SetStateAction<boolean>>;
  }>("AiChatSuperuserDebugInfo");

const AiChatSuperuserDebugInfoButton = () => {
  const { showDebugInfo, setShowDebugInfo } = useAiChatSuperuserDebugInfoContext();
  return (
    <Button onClick={() => setShowDebugInfo((prev) => !prev)} size="xs" className="text-purple-300">
      {showDebugInfo ? "[SU] Hide debug info" : "[SU] Show debug info"}
    </Button>
  );
};

const AiChatSuperuserDebugInfoContent = () => {
  const { messageId, showDebugInfo } = useAiChatSuperuserDebugInfoContext();

  const {
    data: messageProfile,
    isLoading,
    error,
  } = chatMessageDebugInfoQueryHooks.useQuery({
    messageId,
    enabled: showDebugInfo,
  });

  return (
    <>
      {error instanceof Error && <div>Error: {error.message}</div>}
      {isLoading && <div>Loading...</div>}
      {showDebugInfo && messageProfile && (
        <div>
          {messageProfile.logs.map((log, index) => (
            <MessageLogRepDisplay key={index} messageLog={log} />
          ))}
        </div>
      )}
    </>
  );
};

type Props = PropsWithChildren & {
  messageId: string;
};

const AiChatSuperuserDebugInfo: FC<Props> = ({ children, messageId }) => {
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  return (
    <AiChatSuperuserDebugInfoProvider value={{ messageId, showDebugInfo, setShowDebugInfo }}>
      {children}
    </AiChatSuperuserDebugInfoProvider>
  );
};

export default Object.assign(AiChatSuperuserDebugInfo, {
  Button: AiChatSuperuserDebugInfoButton,
  Content: AiChatSuperuserDebugInfoContent,
});
