import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

type Params = {
  chatUserId: string;
};

export const chatChannelsQueryHooks = makeQueryHooks({
  name: "chatChannels",
  useQueryVariables: ({ chatUserId }: Params) => {
    const businessGuid = useBusinessGuid();
    return { businessGuid, chatUserId };
  },
  useQueryFnMaker: ({ businessGuid, chatUserId }) => {
    const highbeamApi = useHighbeamApi();

    return () => {
      return highbeamApi.channelApi.search(businessGuid, chatUserId);
    };
  },
});

export const useChatChannels = (params: Params) => {
  return chatChannelsQueryHooks.useData(params);
};

export const useMvpFirstChatChannel = (params: Params) => {
  const chatChannels = useChatChannels(params);
  return chatChannels[0];
};
