import { ClockCounterClockwise } from "@phosphor-icons/react";
import useUpdateCounterpartyAliasMutation from "modules/counterparty-report/mutations/useUpdateCounterpartyAliasMutation";
import { FC } from "react";
import Helper from "ui/inputs/Helper";
import VirtualButton from "ui/inputs/VirtualButton";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/useModalContext";

import UpdateCounterpartyAliasDropdown from "./UpdateCounterpartyAliasDropdown";
import useUpdateCounterpartyAliasForm from "./useUpdateCounterpartyAliasForm";

export type UpdateCounterpartyAliasModalParams = {
  counterparty: string;
  direction: "money-in" | "money-out";
  originalCounterparties: string[];
};

type UpdateCounterpartyAliasModalContentProps = UpdateCounterpartyAliasModalParams;

const UpdateCounterpartyAliasModalContent: FC<UpdateCounterpartyAliasModalContentProps> = ({
  counterparty,
  direction,
  originalCounterparties,
}) => {
  const { closeModalWithAnimation } = useModalContext();
  const { mutateAsync: updateCounterpartyAlias, isPending } = useUpdateCounterpartyAliasMutation();

  const form = useUpdateCounterpartyAliasForm({
    defaultValues: {
      counterpartyName: counterparty,
    },
  });

  const onSubmit = form.handleSubmit(async (data) => {
    await updateCounterpartyAlias({
      counterparty: data.counterpartyName,
      direction,
      originalCounterparties: originalCounterparties,
    });

    closeModalWithAnimation();
  });

  const originalName = originalCounterparties[0];

  return (
    <ModalV4.Form onSubmit={onSubmit}>
      <ModalV4.Header>Edit {direction === "money-in" ? "source" : "vendor"} name</ModalV4.Header>

      <ModalV4.Body>
        <UpdateCounterpartyAliasDropdown control={form.control} direction={direction} />

        {originalCounterparties.length === 1 && counterparty !== originalName && (
          <VirtualButton onClick={() => form.setValue("counterpartyName", originalName)}>
            <Helper icon={<ClockCounterClockwise size={16} />} className="items-center text-xs">
              Original name: <strong>{originalName}</strong>
            </Helper>
          </VirtualButton>
        )}
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton isLoading={isPending}>Save changes</ModalV4.SubmitButton>
        <ModalV4.CloseButton>Cancel</ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

type Props = {
  params: UpdateCounterpartyAliasModalParams;
  onClose: () => void;
};

const UpdateCounterpartyAliasModal: FC<Props> = ({ params, onClose }) => {
  return (
    <ModalV4 onClose={onClose} dropdownOverflowHack>
      <UpdateCounterpartyAliasModalContent {...params} />
    </ModalV4>
  );
};

export default UpdateCounterpartyAliasModal;
