import HighbeamBaseApi from "api/HighbeamBaseApi";
import { ChannelRep } from "reps/chat/ChannelRep";

export default class ChannelApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(channelId: string): Promise<ChannelRep | null> {
    const url = `/chat/channels/${channelId}`;
    return await this.api.get<ChannelRep>(url);
  }

  async listByBusiness(businessGuid: string): Promise<ChannelRep[]> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/chat/channels?${queryParams}`;
    return (await this.api.get<ChannelRep[]>(url))!;
  }

  async search(businessGuid: string, chatUserId: string): Promise<ChannelRep[]> {
    const queryParams = new URLSearchParams({ businessGuid, chatUserId });
    const url = `/chat/channels?${queryParams}`;
    return (await this.api.get<ChannelRep[]>(url))!;
  }
}
