import {
  QueryFunction,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
  useSuspenseQuery,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import { Merge, Simplify } from "type-fest";

import RequiredButNotFoundError from "./RequiredButNotFoundError";

// NB(alex): Experimental but cautiously optimistic about this! It may be brittle / may not handle all cases yet so please use with caution.

/**
 * Generates a set of query hooks in a standardized format and preserves type-saftey and type inference of types.
 */
const makeQueryHooksV2 = <
  TParams,
  TDefaultParams extends Partial<TParams>,
  TQueryFnParams,
  TQueryData,
  TQueryKey extends QueryKey,
  TPageParam,
  TError,
>(config: {
  useDefaultParams?: () => TDefaultParams;
  makeQueryKey: (params: TParams) => TQueryKey;
  useQueryFnParams: () => TQueryFnParams;
  makeQueryFn: (
    queryFnContext: TQueryFnParams,
    params: TParams
  ) => QueryFunction<TQueryData, TQueryKey, TPageParam>;
}) => {
  const { useDefaultParams, makeQueryKey, useQueryFnParams, makeQueryFn } = config;

  type TParamsWithOptionalDefaults = Merge<TParams, Partial<TDefaultParams>>;

  const useQueryParams = (params: TParamsWithOptionalDefaults) => {
    const defaultParams = useDefaultParams?.();
    return { ...defaultParams, ...params } as TParams;
  };

  const useQueryKey = (params: TParamsWithOptionalDefaults) => {
    const queryParams = useQueryParams(params);
    return makeQueryKey(queryParams);
  };

  const useQueryFn = (params: TParamsWithOptionalDefaults) => {
    const queryFnParams = useQueryFnParams();
    const queryParams = useQueryParams(params);
    return makeQueryFn(queryFnParams, queryParams);
  };

  type BaseSelectFunction = (data: TQueryData) => any;
  type DefaultSelectFunction = (data: TQueryData) => TQueryData;
  type SharedQueryParams<TSelect extends BaseSelectFunction> = {
    params: TParamsWithOptionalDefaults;
    select?: TSelect;
  };
  type OmitQueryOptionKeys = "queryKey" | "queryFn" | "select";

  type UseSuspenseQueryOptionsWithParams<TSelect extends BaseSelectFunction> = Simplify<
    SharedQueryParams<TSelect> &
      Omit<
        UseSuspenseQueryOptions<TQueryData, TError, ReturnType<TSelect>, TQueryKey>,
        OmitQueryOptionKeys
      >
  >;

  type UseQueryParams<TSelect extends BaseSelectFunction> = Simplify<
    SharedQueryParams<TSelect> &
      Omit<UseQueryOptions<TQueryData, TError, ReturnType<TSelect>, TQueryKey>, OmitQueryOptionKeys>
  >;

  return {
    useQueryFnParams: useQueryFnParams,
    useDefaultParams: useDefaultParams,
    makeQueryKey: makeQueryKey,
    makeQueryFn: makeQueryFn,
    useQueryParams: useQueryParams,
    useQueryKey: useQueryKey,
    useQueryFn: useQueryFn,

    useQueryOptions: (params: TParamsWithOptionalDefaults) => {
      return {
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      };
    },

    useQuery<TSelect extends BaseSelectFunction = DefaultSelectFunction>({
      params,
      ...options
    }: UseQueryParams<TSelect>): UseQueryResult<ReturnType<TSelect>, TError> {
      return useQuery({
        ...options,
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      });
    },

    useSuspenseQuery<TSelect extends BaseSelectFunction = DefaultSelectFunction>({
      params,
      ...options
    }: UseSuspenseQueryOptionsWithParams<TSelect>): UseSuspenseQueryResult<
      ReturnType<TSelect>,
      TError
    > {
      return useSuspenseQuery({
        ...options,
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      });
    },

    // NB(alex): Duplicates `this.useSuspenseQuery` but wasn't sure how to extend it without losing types.
    useData<TSelect extends BaseSelectFunction = DefaultSelectFunction>({
      params,
      ...options
    }: UseSuspenseQueryOptionsWithParams<TSelect>): ReturnType<TSelect> {
      const { data } = useSuspenseQuery({
        ...options,
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      });
      return data;
    },

    // NB(alex): Duplicates `this.useSuspenseQuery` but wasn't sure how to extend it without losing types.
    useDataOrThrow<TSelect extends BaseSelectFunction = DefaultSelectFunction>({
      params,
      ...options
    }: UseSuspenseQueryOptionsWithParams<TSelect>): Simplify<NonNullable<ReturnType<TSelect>>> {
      const { data } = useSuspenseQuery({
        ...options,
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      });
      if (data === null) {
        throw new RequiredButNotFoundError();
      }
      return data;
    },

    useEnsureQueryData() {
      const queryClient = useQueryClient();

      return (params: TParams, queryFnParams: TQueryFnParams) => {
        return queryClient.ensureQueryData({
          queryKey: makeQueryKey(params),
          queryFn: makeQueryFn(queryFnParams, params),
        });
      };
    },
  };
};

export default makeQueryHooksV2;
