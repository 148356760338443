import { Coins } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import CapitalAccountTabs, {
  useCapitalAccountTabsSearchParamState,
} from "modules/capital-accounts/components/CapitalAccountTabs";
import RequestAdditionalCapitalButton from "modules/capital-accounts/components/RequestAdditionalCapitalButton";
import useNonTerminatedCapitalAccounts from "modules/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import useTerminatedCapitalAccounts from "modules/capital-accounts/hooks/useTerminatedCapitalAccounts";
import useIsCapitalOverviewPageEnabled from "modules/capital/queries/useIsCapitalOverviewPageEnabled";
import { Navigate } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

// TODO(alex): We will move this view within the `CapitalOverviewPage` when or before rolling out `CAPITAL_OVERVIEW_PAGE`.
import UpsellCapitalView from "../CapitalAccountPage/views/UpsellCapitalView";

import CapitalAccountsList from "./CapitalAccountsList";

const CapitalOverviewContent = () => {
  const isCapitalOverviewPageEnabled = useIsCapitalOverviewPageEnabled();
  const [activeTab, setActiveTab] = useCapitalAccountTabsSearchParamState();
  const terminatedAccounts = useTerminatedCapitalAccounts();
  const nonTerminatedAccounts = useNonTerminatedCapitalAccounts();
  const accounts = activeTab === "Closed" ? terminatedAccounts : nonTerminatedAccounts;

  if (accounts.length === 0) {
    return <UpsellCapitalView />;
  }

  // NB(alex): We can delete this redirect logic once we fully roll out the `CAPITAL_OVERVIEW_PAGE` flag.
  if (!isCapitalOverviewPageEnabled && accounts.length === 1) {
    return <Navigate to={`/capital/${accounts[0].guid}`} />;
  }

  return (
    <>
      <DashboardPage.Header actions={<RequestAdditionalCapitalButton />}>
        <DashboardPage.Header.IconTile icon={<Coins />} />
        <DashboardPage.Header.Title>Capital</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        <CapitalAccountTabs
          className="mb-8 mt-3 w-max"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <CapitalAccountsList accounts={accounts} />
      </DashboardPage.Section>
    </>
  );
};

const CapitalOverviewPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Capital</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CapitalOverviewContent />
      </DashboardPage>
    </>
  );
};

export default CapitalOverviewPage;
