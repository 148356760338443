import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

type Params = {
  channelId: string;
};

export const chatChannelQueryHooks = makeQueryHooks({
  name: "chatChannel",
  useQueryVariables: ({ channelId }: Params) => {
    return { channelId };
  },
  useQueryFnMaker: ({ channelId }) => {
    const highbeamApi = useHighbeamApi();

    return () => {
      return highbeamApi.channelApi.get(channelId);
    };
  },
});

export const useChatChannel = (params: Params) => {
  return chatChannelQueryHooks.useData(params);
};

export const useChatChannelOrThrow = (params: Params) => {
  return chatChannelQueryHooks.useDataRequired(params);
};
