import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

type Params = {
  messageId: string;
};

export const chatMessageDebugInfoQueryHooks = makeQueryHooks({
  name: "chatMessageDebugInfo",
  useQueryVariables: ({ messageId }: Params) => {
    return { messageId };
  },
  useQueryFnMaker: ({ messageId }) => {
    const highbeamApi = useHighbeamApi();
    return () => highbeamApi.messageProfile.getByMessage(messageId);
  },
});
