import React from "react";
import { ChatConfigRep } from "reps/chat/ChatConfigRep";

import ChatStatusItem from "../ChatStatusItem";

import ChatSettingsIsolatedDataViewItemHydrateNowButton from "./ChatSettingsIsolatedDataViewItemHydrateNowButton";
import ChatSettingsIsolatedDataViewItemIcon from "./ChatSettingsIsolatedDataViewItemIcon";
import ChatSettingsIsolatedDataViewItemText from "./ChatSettingsIsolatedDataViewItemText";

type Props = {
  chatConfig: ChatConfigRep;
};

const ChatSettingsIsolatedDataViewItem: React.FC<Props> = ({ chatConfig }) => {
  return (
    <ChatStatusItem>
      <ChatSettingsIsolatedDataViewItemIcon chatConfig={chatConfig} />
      <ChatSettingsIsolatedDataViewItemText chatConfig={chatConfig} />
      <ChatSettingsIsolatedDataViewItemHydrateNowButton chatConfig={chatConfig} />
    </ChatStatusItem>
  );
};

export default ChatSettingsIsolatedDataViewItem;
